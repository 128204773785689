import axios from 'axios';
import config from '../config';
import LocalStorageHelper from '../helpers/local-storage-helper';
import AuthService from '../services/auth-service';

const authorizedApi = axios.create({
  baseURL: `${config.apiUrl}`,
  timeout: 20000,
});

authorizedApi.interceptors.request.use(
  (config) => {
    const token = LocalStorageHelper.getSessionToken();

    if (!!token) {
      config.headers.Authorization = `Bearer ${token}`;
      return config;
    } else {
      window.location.href = '/login';
      return Promise.reject(new Error('No token available, request canceled'));
    }
  },
  (error) => Promise.reject(error)
);

authorizedApi.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;

    if (error.response?.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;

      try {
        const token = await AuthService.refreshToken();

        if (token) {
          originalRequest.headers.Authorization = `Bearer ${token}`;
          return axios(originalRequest);
        } else {
          throw new Error('Failed to refresh token');
        }
      } catch (refreshError) {
        window.location.href = '/login';
      }
    }

    return Promise.reject(error);
  }
);

export default authorizedApi;