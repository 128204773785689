import UserAPI from "../api/user-api";
import UserFirmAPI from "../api/user-firm-api";
import { JwtDto } from "../dto/jwt-dto";
import { CredentialsDto } from "../dto/users/credentials-dto";
import { UserFirmJunctionDto } from "../dto/users/user-firm-junction-dto";
import LocalStorageHelper from "../helpers/local-storage-helper";
import UserHelper from "../helpers/user-helper";
import { User } from "../models/user";

// Info: Don't use this outside of AuthContext (api.tsx is an exception, need a better solution for api.tsx), all these functions affect tokens or the authentication status
const AuthService = {
  register: async (user: User): Promise<number> => {
    const createUserDto = UserHelper.userToCreate(user);
    return await UserAPI.register(createUserDto);
  },

  login: async (username: string, password: string) => {
    const credentials: CredentialsDto = { username, password };
    const jwt = await UserAPI.login(credentials);
    LocalStorageHelper.storeSessionToken(jwt.accessToken!);
    LocalStorageHelper.storeRefreshToken(jwt.refreshToken!);
  },

  logout: async () => {
    await UserAPI.logout();
    LocalStorageHelper.removeSessionToken();
    LocalStorageHelper.removeRefreshToken();
    LocalStorageHelper.removeRapportFilter();
  },

  joinFirm: async (firmLicence: string, userLicence: string, userId: number, isAdmin: boolean) => {
    const userFirmJunctionDto: UserFirmJunctionDto = { userLicence: userLicence, userId: userId, firmLicence: firmLicence, isAdmin: isAdmin };
    await UserFirmAPI.joinFirm(userFirmJunctionDto);
  },

  leaveFirm: async (firmLicence: string, userLicence: string, userId: number) => {
    const userFirmJunctionDto: UserFirmJunctionDto = { firmLicence: firmLicence, userLicence: userLicence, userId: userId, isAdmin: false };
    await UserFirmAPI.leaveFirm(userFirmJunctionDto);
  },

  refreshToken: async (): Promise<string | null> => {
    const token = LocalStorageHelper.getSessionToken();
    if (!!!token) return null;

    const jwtDto: JwtDto = {
      accessToken: token,
      refreshToken: LocalStorageHelper.getRefreshToken(),
    };

    try {
      const jwt = await UserAPI.refreshToken(jwtDto);
      LocalStorageHelper.storeSessionToken(jwt);
      return jwt;
    } catch {
      return null;
    }
  },
};

export default AuthService;