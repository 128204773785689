import { Alert, IconButton, Pagination, Paper, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import { Address } from "../models/address";
import { useContext, useEffect, useState } from "react";
import { User } from "../models/user";
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { DeleteDialog } from "./Dialogs/Dialog";
import { MetaContext } from "./Provider/MetaContextProvider";
import AddressService from "../services/address-service";
import AddressDialog from "./Dialogs/AddressDialog";
import { ConditionalTableCell } from "../styles/styles";
import { FirmContext } from "./Provider/FirmContextProvider";
import { MeContext } from "./Provider/MeContextProvider";

type AddressBookTableProps = {
  isDetailed: boolean;
  size?: number;
};

const AddressBookTable = (props: AddressBookTableProps) => {
  const context = useContext(MetaContext);
  const meContext = useContext(MeContext);
  const firmContext = useContext(FirmContext);
  const [page, setPage] = useState(1);
  const [currentAddress, setCurrentAddress] = useState<Address | null>(null);
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);

  const pageSize = 100;

  const handleChangePage = (_: React.ChangeEvent<unknown>, page: number) => {
    firmContext.fetchAddressBook(page, pageSize);
    setPage(page);
  };

  const handleEditClick = (address: Address) => {
    setCurrentAddress(address);
    setEditDialogOpen(true);
  };

  const handleDeleteClick = (address: Address) => {
    setCurrentAddress(address);
    setDeleteDialogOpen(true);
  };

  const saveAddress = async (address: Address) => {
    const id = await context.handleAsyncOperation(
      () => AddressService.updateAddress(address.id, address)
    );

    if (id !== null) {
      context.setAlertMessage('Die Adresse wurde erfolgreich gespeichert.');
      context.setAlertSeverity('success');
      firmContext.setAddressBook(prev => prev.map(x => (x.id === id) ? address : x));
    } else {
      context.setAlertMessage("Ein Fehler ist beim Hinzufügen ins Adressbuch aufgetreten.");
      context.setAlertSeverity("error");
    }

    setEditDialogOpen(false);
  };

  const deleteAddress = async () => {
    if (currentAddress === null) {
      return;
    }

    await context.handleAsyncOperation(
      () => AddressService.deleteAddress(currentAddress.id)
    );

    firmContext.setAddressBook(prev => prev.filter(x => x.id !== currentAddress.id));

    setDeleteDialogOpen(false);
    setCurrentAddress(null);
  };

  if (firmContext.addressBook.length === 0) {
    return (
      <Alert severity='info' variant="outlined">Keine Adressen gefunden</Alert>
    );
  }

  let addressBook;
  if (props.size !== null) {
    addressBook = firmContext.addressBook.slice(0, props.size);
  } else {
    addressBook = firmContext.addressBook;
  }

  return (
    <Stack>
      <TableContainer sx={{ borderRadius: 0 }} component={Paper}>
        <Table sx={{ border: 1, borderColor: 'black' }}>
          <TableHead>
            <TableRow sx={{ backgroundColor: theme => theme.palette.grey[400], borderBottom: 2 }}>
              <TableCell>Name</TableCell>
              <ConditionalTableCell>Strasse</ConditionalTableCell>
              <ConditionalTableCell>Stadt</ConditionalTableCell>
              {props.isDetailed && <TableCell colSpan={2} />}
            </TableRow>
          </TableHead>
          <TableBody>{addressBook.map((address, index) =>
            <TableRow
              key={index}
              sx={{
                '&:last-child td, &:last-child th': { border: 0, borderColor: theme => theme.palette.grey[400] },
                '&:nth-of-type(even)': { backgroundColor: theme => theme.palette.grey[50] },
                '&:nth-of-type(even), &:nth-of-type(odd)': { backgroundColor: theme => (address.id === currentAddress?.id) ? theme.palette.primary.lighter : '' },
              }}
            >
              <TableCell>{address.firstname === '' ? address.lastname : `${address.firstname} ${address.lastname}`}</TableCell>
              <ConditionalTableCell>{address.street}</ConditionalTableCell>
              <ConditionalTableCell>{address.city}</ConditionalTableCell>
              {props.isDetailed &&
              <>
                <TableCell align="right">
                  {(meContext.currentUser?.isAdmin) && <IconButton sx={{padding: theme => theme.spacing(0)}} onClick={() => handleEditClick(address)}><EditIcon /></IconButton>}
                </TableCell>
                <TableCell align="right">
                  {(meContext.currentUser?.isAdmin) && <IconButton sx={{padding: theme => theme.spacing(0)}} onClick={() => handleDeleteClick(address)}><DeleteIcon /></IconButton>}
                </TableCell>
              </>
              }
            </TableRow>
            )}
          </TableBody>
        </Table>
        {(props.isDetailed && firmContext.addressBookCount > pageSize) && <Pagination count={Math.ceil(firmContext.addressBookCount / pageSize)} page={page} onChange={handleChangePage} />}
      </TableContainer>
      <AddressDialog address={currentAddress!} isOpen={editDialogOpen} onSave={saveAddress} onClose={() => {setEditDialogOpen(false); setCurrentAddress(null);}} />
      <DeleteDialog title="Löschen" description="Wollen Sie die ausgewählte Adresse löschen?" isOpen={deleteDialogOpen} onClose={() => {setDeleteDialogOpen(false); setCurrentAddress(null);}} onConfirm={deleteAddress} />
    </Stack>
  );
}

export default AddressBookTable;