import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { Divider, Menu, PopoverPosition, Tooltip } from '@mui/material';
import { Row } from '../../styles/styles';
import DynamicIcon from '../DynamicIcon';
import { PriceTotal } from '../../models/price-total';
import { roundCHF } from '../../helpers/utils';

type TotalDialogProps = {
  priceTotal: PriceTotal;
  anchorEl: PopoverPosition | null;
  isOpen: boolean;
  onClose: () => void;
};

const TotalDialog = (props: TotalDialogProps) => {

  return (
    <Menu
      anchorReference='anchorPosition'
      anchorPosition={props.anchorEl!}
      open={props.isOpen}
      onClose={props.onClose}
    >
      <Stack sx={{ paddingLeft: 1, paddingRight: 1, gap: 1 }}>
        <Row sx={{ justifyContent: 'space-between', gap: 2 }}>
          <Row gap={1}>
            <DynamicIcon fontSize='small' iconName="HourglassBottom" />
            <Typography>Arbeitszeit</Typography>
          </Row>
          <Typography>{props.priceTotal.worktimeTotal.toFixed(2)} CHF</Typography>
        </Row>
        <Row sx={{ justifyContent: 'space-between', gap: 2 }}>
          <Row sx={{ gap: 1, }}>
            <DynamicIcon iconName='ElectricalServices' fontSize='small' />
            <Typography>Material</Typography>
          </Row>
          <Typography>{props.priceTotal.materialTotal.toFixed(2)} CHF</Typography>
        </Row>
        {props.priceTotal.discountAmount > 0 && <Row sx={{ justifyContent: 'space-between', gap: 2 }}>
          <Typography>Rabatt</Typography>
          <Typography>-{props.priceTotal.discountAmount.toFixed(2)} CHF</Typography>
        </Row>}
        <Divider />
        <Row sx={{ justifyContent: 'space-between', gap: 2 }}>
          <Typography>Brutto</Typography>
          <Typography>{props.priceTotal.netTotal.toFixed(2)} CHF</Typography>
        </Row>
        <Row sx={{ justifyContent: 'space-between', gap: 2 }}>
          <Typography>MWST</Typography>
          <Typography>{props.priceTotal.vatAmount.toFixed(2)} CHF</Typography>
        </Row>
        <Divider />
        <Row sx={{ justifyContent: 'space-between', gap: 2 }}>
          <Typography fontWeight='bold'>Netto</Typography>
          <Typography fontWeight='bold'>{props.priceTotal.total.toFixed(2)} CHF</Typography>
        </Row>
      </Stack>
    </Menu>
  );
};

export default TotalDialog;