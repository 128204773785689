// Info: Careful, these are used to determine the beName field
export type RapportItemDto = {
  worktime: boolean | null;
  material: boolean | null;
  measurements: boolean | null;
  jobs: boolean | null;
  attachments: boolean | null;
  signature: boolean | null;
  rapportInfo: boolean | null;
};

export const defaultRapportItem: RapportItemDto = {
  worktime: false,
  material: false,
  measurements: false,
  jobs: false,
  attachments: false,
  signature: false,
  rapportInfo: false,
};