import UserFirmAPI from "../api/user-firm-api";
import { User } from "../models/user";
import UserHelper from "../helpers/user-helper";
import FirmHelper from "../helpers/firm-helper";
import { Firm } from "../models/firm";
import { UserFirmConnectDto } from "../dto/users/user-firm-connect-dto";
import { WorkloadAmountDto } from "../dto/workload-amount-dto";

const UserFirmService = {
  getAllUsers: async (searchTerm?: string): Promise<User[]> => {
    const userDtos = await UserFirmAPI.getAllUsers(searchTerm);
    const users = userDtos.map((userDto) =>
      UserHelper.userFromDto(userDto)
    );
    
    return users;
  },

  getFirmOfUser: async (): Promise<Firm | null> => {
    const firmDto = await UserFirmAPI.getFirmOfUser();

    if (!!!firmDto) {
      return null;
    }
    
    return FirmHelper.firmFromDto(firmDto);
  },

  getUserLicence: async (): Promise<string | null> => {
    return await UserFirmAPI.getUserLicence();
  },

  getMemberCount: async (admin?: boolean): Promise<number | null> => {
    return await UserFirmAPI.getMemberCount(admin);
  },

  licenceAvailableQuarantine: async (firmLicence: string, userLicence: string): Promise<boolean> => {
    const userFirmConnectDto: UserFirmConnectDto = { firmLicence: firmLicence, userLicence: userLicence };
    return await UserFirmAPI.licenceAvailableQuarantine(userFirmConnectDto);
  },

  removeUser: async (userId: number) => {
    await UserFirmAPI.removeUser(userId);
  },

  updateAdminPermission: async (userId: number, isAdmin: boolean): Promise<User> => {
    const userDto = await UserFirmAPI.updateAdminPermission(userId, isAdmin);
    return UserHelper.userFromDto(userDto);
  },

  updateWorkloadAmount: async (userId: number, workload: number): Promise<User>  => {
    const workloadAmountDto = {
      workload: workload,
    } as WorkloadAmountDto;

    const userDto = await UserFirmAPI.updateWorkloadAmount(userId, workloadAmountDto);
    return UserHelper.userFromDto(userDto);
  },
}

export default UserFirmService;