import Dialog from '@mui/material/Dialog';
import { SlideUpTransition } from '../../styles/styles';
import { Stack } from '@mui/material';
import JobsItem from '../Items/JobsItem';
import MaterialItem from '../Items/MaterialItem';
import MeasurementsItem from '../Items/MeasurementsItem';
import AttachmentsItem from '../Items/AttachmentsItem';
import DialogTitlebar from '../DialogTitlebar';
import SignatureItem from '../Items/SignatureItem';
import WorktimeItem from '../Items/WorktimeItem';
import RapportInfoItem from '../Items/RapportInfoItem';
import { RapportContext } from '../Provider/RapportContextProvider';
import { useContext } from 'react';

export type ItemProps = {
  updateIsFilled: (isFilled: boolean) => void;
};

const ItemDialog = () => {
  const rapportContext = useContext(RapportContext);
  
  const updateIsFilled = (isFilled: boolean) => {
    const index = rapportContext.rapport.entries.findIndex(x => x.beName === rapportContext.selectedItem?.beName);

    if (index !== -1) {
      const updatedEntries = [...rapportContext.rapport.entries];
      updatedEntries[index] = { ...updatedEntries[index], isActive: isFilled };
  
      rapportContext.setRapport({
        ...rapportContext.rapport,
        entries: updatedEntries,
      });
    }
  };

  const renderItemDialog = () => {
    switch (rapportContext.selectedItem?.beName) {
      case 'rapportInfo':
        return <RapportInfoItem updateIsFilled={updateIsFilled} />
      case 'worktime':
        return <WorktimeItem updateIsFilled={updateIsFilled} />;
      case 'material':
        return <MaterialItem updateIsFilled={updateIsFilled} />
      case 'measurements':
        return <MeasurementsItem updateIsFilled={updateIsFilled} />;
      case 'jobs':
        return <JobsItem updateIsFilled={updateIsFilled} />;
      case 'attachments':
        return <AttachmentsItem updateIsFilled={updateIsFilled} />;
      case 'signature':
        return <SignatureItem updateIsFilled={updateIsFilled} />;
    }
  };

  return (
    <Dialog
      fullScreen
      open={rapportContext.selectedItem !== null}
      onClose={() => rapportContext.setSelectedItem(null)}
      TransitionComponent={SlideUpTransition}
    >
      <DialogTitlebar title={rapportContext.selectedItem?.name ?? ''} onClose={() => rapportContext.setSelectedItem(null)}/>
      <Stack sx={{ overflowY: 'auto', padding: { sm: 2 }, paddingTop: '0 !important'}}>
        {renderItemDialog()}
      </Stack>
    </Dialog>
  );
};

export default ItemDialog;
