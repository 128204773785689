import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import Divider from '@mui/material/Divider';
import SettingsIcon from '@mui/icons-material/Settings';
import LogoutIcon from '@mui/icons-material/Logout';
import PersonIcon from '@mui/icons-material/Person';
import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { MetaContext } from '../Provider/MetaContextProvider';
import { Contextmenu } from '../../styles/styles';
import { Theme, useMediaQuery } from '@mui/material';
import { AuthContext } from '../Provider/AuthContextProvider';

type ProfileContextmenuProps = {
  anchorEl: HTMLElement | null;
  isOpen: boolean;
  onClose: () => void;
};

const ProfileContextmenu = (props: ProfileContextmenuProps) => {
  const context = useContext(MetaContext);
  const authContext = useContext(AuthContext);
  const navigate = useNavigate();

  const isXsOrSm = useMediaQuery((theme: Theme) => theme.breakpoints.down('lg'));

  const handleLogout = async () => {
    try {
      context.setIsLoading(true);
      await authContext.logout();
      navigate('/login');
    } finally {
      context.setIsLoading(false);
    }
  };

  return (
    <Contextmenu
      anchorEl={props.anchorEl}
      open={props.isOpen}
      onClose={props.onClose}
      onClick={props.onClose}
    >
      <MenuItem onClick={() => navigate('/profile')}>
        <ListItemIcon>
          <PersonIcon />
        </ListItemIcon>
        Profil
      </MenuItem>
      {isXsOrSm && <Divider />}
      {isXsOrSm && <MenuItem onClick={() => navigate('/settings')}>
        <ListItemIcon>
          <SettingsIcon fontSize="small" />
        </ListItemIcon>
        Einstellungen
      </MenuItem>}
      <MenuItem onClick={handleLogout}>
        <ListItemIcon>
          <LogoutIcon fontSize="small" />
        </ListItemIcon>
        Logout
      </MenuItem>
    </Contextmenu>
  );
}

export default ProfileContextmenu;