export type PriceTotal = {
  rapportId: string;
  materialTotal: number;
  worktimeTotal: number;
  netTotal: number;
  vatAmount: number;
  discountAmount: number;
  total: number;
};

export const defaultPriceTotal: PriceTotal = {
  rapportId: "",
  materialTotal: 0,
  worktimeTotal: 0,
  netTotal: 0,
  vatAmount: 0,
  discountAmount: 0,
  total: 0,
};