import MaterialAPI from "../api/material-api";
import { EldasDto } from "../dto/catalogs/eldas-dto";
import { NpkDto } from "../dto/catalogs/npk-dto";
import { SteigerDto } from "../dto/catalogs/steiger-dto";
import MaterialHelper from "../helpers/material-helper";
import { Eldas } from "../models/eldas";
import { Npk } from "../models/npk";
import { Steiger } from "../models/steiger";

const MaterialService = {
  getMaterialWithPrice: async (id: number, catalog: string): Promise<Steiger | Eldas | Npk | null> => {
    const materialDto = await MaterialAPI.getMaterialWithPrice(id, catalog);
    if (catalog === 'steiger') {
      return MaterialHelper.steigerFromDto(materialDto as SteigerDto);
    } else if (catalog === 'eldas') {
      return MaterialHelper.eldasFromDto(materialDto as EldasDto);
    } else if (catalog === 'npk') {
      return MaterialHelper.npkFromDto(materialDto as NpkDto);
    } else {
      return null;
    }
  },

  searchSteigerMaterial: async (id?: number, page?: number, pageSize?: number, search?: string): Promise<Steiger[]> => {
    const materialDtos = await MaterialAPI.searchSteigerMaterial(id, page, pageSize, search);
    
    return materialDtos.map((materialDto) =>
      MaterialHelper.steigerFromDto(materialDto)
    );
  },

  searchEldasMaterial: async (page?: number, pageSize?: number, search?: string): Promise<Eldas[]> => {
    const materialDtos = await MaterialAPI.searchEldasMaterial(page, pageSize, search);

    return materialDtos.map((materialDto) =>
      MaterialHelper.eldasFromDto(materialDto)
    );
  },

  searchNpkMaterial: async (id?: number, page?: number, pageSize?: number, search?: string): Promise<Npk[]> => {
    const materialDtos = await MaterialAPI.searchNpkMaterial(id, page, pageSize, search);

    return materialDtos.map((materialDto) =>
      MaterialHelper.npkFromDto(materialDto)
    );
  },

  loadPrices: async (): Promise<string> => {
    return await MaterialAPI.loadPrices();
  },

  cancelPriceLoading: async (): Promise<boolean> => {
    return await MaterialAPI.cancelPriceLoading();
  },
}

export default MaterialService;