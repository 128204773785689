import PostItAPI from "../api/post-it-api";
import PostItHelper from "../helpers/post-it-helper";
import { PostIt } from "../models/post-it";

const PostItService = {
  syncPostItQuarantine: async (): Promise<PostIt[]> => {
    const dtos = await PostItAPI.syncPostItQuarantine();
    return dtos.map(dto => PostItHelper.postItFromDto(dto));
  },

  getPostIts: async (): Promise<PostIt[]> => {
    const dtos = await PostItAPI.getPostIts();
    return dtos.map(dto => PostItHelper.postItFromDto(dto));
  },

  addPostIt: async (message: string): Promise<PostIt> => {
    const dto = await PostItAPI.addPostIt(message);
    return PostItHelper.postItFromDto(dto);
  },

  updatePostIt: async (id: number, message: string): Promise<PostIt> => {
    const dto = await PostItAPI.updatePostIt(id, message);
    return PostItHelper.postItFromDto(dto);
  },

  deletePostIt: async (id: number) => {
    await PostItAPI.deletePostIt(id);
  },
};

export default PostItService;