import axios from "axios";
import { UserDto } from "../dto/users/user-dto";
import authorizedApi from "./api";
import { JwtDto } from "../dto/jwt-dto";
import { CredentialsDto } from "../dto/users/credentials-dto";
import { UpdateUserDto } from "../dto/users/update-user-dto";
import config from "../config";
import { CreateUserDto } from "../dto/users/create-user-dto";

const UserAPI = {
  getCurrentUser: async (): Promise<UserDto> => {
    const response = await authorizedApi.get(`/users/current`);
    return response.data as UserDto;
  },

  updateUser: async (updateUserDto: UpdateUserDto): Promise<number> => {
    const response = await authorizedApi.put(`/users`, updateUserDto);
    return response.data as number;
  },

  deleteUser: async () => {
    await authorizedApi.delete(`/users`);
  },

  usernameExists: async (username: string): Promise<boolean> => {
    const response = await axios.get(`${config.apiUrl}/users/${username}`);
    return response.data as boolean;
  },

  register: async (createUserDto: CreateUserDto): Promise<number> => {
    const response = await axios.post(`${config.apiUrl}/users/registration`, createUserDto);
    return response.data as number;
  },

  login: async (credentialsDto: CredentialsDto): Promise<JwtDto> => {
    const response = await axios.post(`${config.apiUrl}/users/login`, credentialsDto);
    return response.data as JwtDto;
  },

  logout: async () => {
    await authorizedApi.post(`/users/logout`);
  },

  refreshToken: async (jwtDto: JwtDto): Promise<string> => {
    const response = await axios.post(`${config.apiUrl}/users/refresh-token`, jwtDto);
    return response.data as string;
  },

  updatePassword: async (credentialsDto: CredentialsDto): Promise<number> => {
    const response = await authorizedApi.post(`/users/password`, credentialsDto);
    return response.data as number;
  },
}

export default UserAPI;
