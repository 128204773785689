import { Typography } from "@mui/material";

const InfoText = {
  registration: (
    <Typography>
      Um sämtliche Funktionen nutzen zu können, empfehlen wir Ihnen, Ihr Konto mit dem SchochAUFTRAG 'Alfred' Programm zu verbinden. Hierfür ist es erforderlich, im SchochAuftrag eine neue Benutzer-Lizenz zu erstellen. Sie können dies unter 'Einstellungen' {"->"} 'Erstellen von Benutzern für MobileApp' durchführen. Bitte beachten Sie, dass die Verknüpfung auch nach der Erstellung des Kontos erfolgen kann.
    </Typography>
  ),
  connectAccount: (
    <Typography>
      Um sämtliche Funktionen nutzen zu können, empfiehlt es sich, Ihr Konto mit dem SchochAUFTRAG 'Alfred' Programm zu verknüpfen. Dazu ist es erforderlich, eine neue Benutzerlizenz im SchochAuftrag zu erstellen. Dies kann unter dem Menüpunkt 'Einstellungen' {"->"} 'Benutzer für MobileApp erstellen' vorgenommen werden.
    </Typography>
  ),
  jobInfo: (
    <Typography>
      Ein Administrator der Firma kann firmenweite Tätigkeiten hinzufügen, die in der Auswahlliste erscheinen. Wenn Sie eine Tätigkeit speziell nur für diesen Rapport erstellen wollen, dann können Sie diesen eingeben.
    </Typography>
  ),
  smartFill: (
    <Typography>
      Dank der neuen 'Smart-Fill'-Funktionalität werden häufig verwendete Formulare automatisch mit Adressen ausgefüllt. Diese Adressen werden direkt aus dem Rapport übernommen und müssen bei fehlerhaften Angaben dort korrigiert werden.
    </Typography>
  ),
  uncheckedWorktime: (
    <Typography>
      Arbeitszeit, die in einem Rapport erfasst wurde. Bevor diese Arbeitszeit in Ihre Zeiterfassung übernommen wird, müssen Sie diese zunächst bestätigen.
    </Typography>
  ),
  weeklyOverview: (
    <Typography>
      Sie können die Legende anklicken, um die Stunden anzuzeigen. Falls die Sollzeit erreicht wurde, wird die Grafik farbig dargestellt; andernfalls erscheint sie in einem Grauton.
    </Typography>
  ),
  loadPrices: (
    <Typography>
      Zuerst müssen die Preis-Informationen im SchochAUFTRAG 'Alfred' an die App übermittelt werden. Anschließend können Sie die Daten hier laden. Der Prozess dauert etwa 15 Minuten, aber Sie können während dieser Zeit problemlos weiterarbeiten. 
    </Typography>
  ),
  defaultPostIts: (
    <Typography>
      Für die Empfangsbestätigung können vorgängig Vorgang-Merkmale erfasst werden, die von allen Firmenmitgliedern verwendet werden können.
    </Typography>
  ),
};

export default InfoText;