import { Alert, IconButton, Paper, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField } from "@mui/material";
import { ChangeEvent, useContext, useState } from "react";
import { MetaContext } from "./Provider/MetaContextProvider";
import PostItService from "../services/post-it-service";
import EditIcon from '@mui/icons-material/Edit';
import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';
import CheckIcon from '@mui/icons-material/Check';
import { MeContext } from "./Provider/MeContextProvider";
import { FirmContext } from "./Provider/FirmContextProvider";

type PostItTableProps = {
  size?: number;
};

const PostItTable = (props: PostItTableProps) => {
  const context = useContext(MetaContext);
  const firmContext = useContext(FirmContext);
  const meContext = useContext(MeContext);
  const [message, setMessage] = useState("");

  const addPostIt = async (message: string) => {
    const data = await context.handleAsyncOperation(
      () => PostItService.addPostIt(message)
    );

    if (data !== null) {
      meContext.setPostIts(prev => [data, ...prev]);
    }

    reset();
  }

  const updateMessage = (evt: ChangeEvent<HTMLInputElement>) => {
    const newValue = evt.target && evt.target.value;
    setMessage(newValue);
  };



  const handleEditButtonClick = async (id: number) => {
    const data = await context.handleAsyncOperation(
      () => PostItService.updatePostIt(id, message)
    );

    if (data !== null) {
      meContext.setPostIts(prev => prev.map(x => x.id === id ? data : x));
    }

    reset();
  };

  const handleDeleteButtonClick = async (id: number) => {
    await context.handleAsyncOperation(
      () => PostItService.deletePostIt(id)
    );

    meContext.setPostIts(prev => prev.filter(x => x.id !== id));

    reset();
  };

  const reset = () => {
    firmContext.setUpdatePostItIndex(null);
    setMessage("");
  }

  const editRow = (changeFunction: (...args: any[]) => void) => {
    return (
      <TableRow>
        <TableCell>
          <TextField
            autoFocus
            value={message}
            onChange={updateMessage}
          />
        </TableCell>
        <TableCell align="right">
          <IconButton onClick={changeFunction}>
            <CheckIcon />
          </IconButton>
          <IconButton onClick={reset}>
            <CloseIcon />
          </IconButton>
        </TableCell>
      </TableRow>
    );
  };

  if (meContext.postIts.length === 0) {
    return (
      <Alert severity='info' variant="outlined" sx={{ flex: 1, margin: { xs: 2, sm: 0, } }}>Keine Ergebnisse gefunden</Alert>
    );
  }

  let postIts;
  if (props.size !== null) {
    postIts = meContext.postIts.slice(0, props.size);
  } else {
    postIts = meContext.postIts;
  }
  
  return (
    <Stack>
      <TableContainer sx={{ borderRadius: 0 }} component={Paper}>
        <Table sx={{ border: 1, borderColor: 'black' }}>
          <TableHead>
            <TableRow sx={{ backgroundColor: theme => theme.palette.grey[400], borderBottom: 2, }}>
              <TableCell>Vorgang-Merkmal</TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
          {firmContext.updatePostItIndex === -1 && editRow(() => addPostIt(message))}
          {postIts.map((postIt, index) =>
            firmContext.updatePostItIndex === index ? editRow(() => handleEditButtonClick(postIt.id)) :
            <TableRow
              key={index}
              sx={{
                '&:last-child td, &:last-child th': { border: 0, borderColor: theme => theme.palette.grey[400] },
                '&:nth-of-type(even)': { backgroundColor: theme => theme.palette.grey[50] },
              }}
            >
              <TableCell>{postIt.message}</TableCell>
              <TableCell align="right" sx={{ width: 90 }}>
                <IconButton onClick={() => { firmContext.setUpdatePostItIndex(index); setMessage(postIt.message);}}>
                  <EditIcon />
                </IconButton>
                <IconButton onClick={() => handleDeleteButtonClick(postIt.id)}>
                  <DeleteIcon />
                </IconButton>
              </TableCell>
            </TableRow>
          )}
          </TableBody>
        </Table>
      </TableContainer>
    </Stack>
  );
};

export default PostItTable;