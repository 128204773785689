import { WorktimeDto } from "../dto/worktime-dto";
import { WorktimeWeekDto } from "../dto/worktime-week-dto";
import authorizedApi from "./api";

const WorktimeAPI = {
  getUncheckedWorktimes: async (): Promise<WorktimeDto[]> => {
    const response = await authorizedApi.get(`/worktime/`);
    return response.data as WorktimeDto[];
  },

  getWeek: async (startOfWeek: string): Promise<WorktimeWeekDto> => {
    const params: Record<string, any> = { startOfWeek }
    const response = await authorizedApi.get(`worktime/week/`, { params });
    return response.data as WorktimeWeekDto;
  },

  addWorktime: async (worktimeDto: WorktimeDto): Promise<number> => {
    const response = await authorizedApi.post(`/worktime/`, worktimeDto);
    return response.data as number;
  },

  updateWorktime: async (id: number, worktimeDto: WorktimeDto): Promise<number> => {
    const response = await authorizedApi.put(`/worktime/${id}`, worktimeDto);
    return response.data as number;
  },

  checkWorktime: async (id: number): Promise<number> => {
    const response = await authorizedApi.put(`/worktime/${id}/check`);
    return response.data as number;
  },

  deleteWorktime: async (id: number) => {
    await authorizedApi.delete(`/worktime/${id}`)
  },

  deleteWorktimes: async (ids: number[]) => {
    await authorizedApi.delete(`worktime/delete-ids`, { data: ids });
  },
}

export default WorktimeAPI;