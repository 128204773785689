import { Button, IconButton, Tooltip } from '@mui/material';
import React from 'react';
import DynamicIcon from './DynamicIcon';

interface GoogleMapsLinkProps {
  address: string;
}

const GoogleMapsLink: React.FC<GoogleMapsLinkProps> = ({ address }) => {
  const openInGoogleMaps = () => {
    const url = `https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(address)}`;
    window.open(url, '_blank');
  };

  return (
    <Tooltip title="In Google Maps öffnen">
      <IconButton onClick={openInGoogleMaps}>
        <DynamicIcon iconName="OpenInNew" />
      </IconButton>
    </Tooltip>
  );
};

export default GoogleMapsLink;
