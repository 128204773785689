import { createContext, useState, ReactNode, useEffect } from "react";
import AuthService from "../../services/auth-service";
import { User } from "../../models/user";
import LocalStorageHelper from "../../helpers/local-storage-helper";

type AuthContextType = {
  isAuthenticated: boolean,
  isLoading: boolean,
  register: (user: User) => Promise<number>,
  login: (username: string, password: string) => Promise<void>,
  logout: () => Promise<void>,
  refreshToken: () => Promise<string | null>,
  joinFirm: (firmLicence: string, userLicence: string, userId: number, isAdmin: boolean) => Promise<void>,
  leaveFirm: (firmLicence: string, userLicence: string, userId: number) => Promise<void>,
};

export const AuthContext = createContext<AuthContextType>({
  isAuthenticated: false,
  isLoading: true,
  register: async () => 0,
  login: async () => {},
  logout: async () => {},
  refreshToken: async () => null,
  joinFirm: async () => {},
  leaveFirm: async () => {},
});

type AuthContextProviderProps = {
  children: ReactNode;
};

const AuthContextProvider = (props: AuthContextProviderProps) => {
  const [token, setToken] = useState<string | null>(null);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isInitialized, setIsInitialized] = useState(false);

  const initializeAuth = async () => {
    try {
      setIsLoading(true);
      const token = LocalStorageHelper.getSessionToken();
      if (!!!token) return false;
      const newToken = await AuthService.refreshToken();
      setToken(newToken);
      setIsAuthenticated(!!newToken);
    } finally {
      setIsLoading(false);
    }
  };

  const register = async (user: User): Promise<number> => {
    const userId = await AuthService.register(user);
    return userId;
  };

  const login = async (username: string, password: string) => {
    try {
      setIsLoading(true);
      await AuthService.login(username, password);
      setIsAuthenticated(true);
    } catch (error) {
      setIsAuthenticated(false);
    } finally {
      setIsLoading(false);
    }
  };

  const logout = async () => {
    try {
      setIsLoading(true);
      await AuthService.logout();
      setIsAuthenticated(false);
    } finally {
      setIsLoading(false);
    }
  };

  const refreshToken = async (): Promise<string | null> => {
    const newToken = await AuthService.refreshToken();
    setToken(newToken);
    return newToken;
  };

  const joinFirm = async (firmLicence: string, userLicence: string, userId: number, isAdmin: boolean) => {
    try {
      setIsLoading(true);
      await AuthService.joinFirm(firmLicence, userLicence, userId, isAdmin);
      await refreshToken();
    } finally {
      setIsLoading(false);
    }
  };

  const leaveFirm = async (firmLicence: string, userLicence: string, userId: number) => {
    try {
      setIsLoading(true);
      await AuthService.leaveFirm(firmLicence, userLicence, userId);
      await refreshToken();
    } finally {
      setIsLoading(false);
    }
  };


  useEffect(() => {
    if (!isInitialized) {
      initializeAuth();
      setIsInitialized(true);
    }

    let refreshInterval: NodeJS.Timer | undefined = undefined;

    if (isAuthenticated) {
      refreshInterval = setInterval(async () => {
        try {
          const token = await refreshToken();
          if (!token) {
            await logout();
          }
        } catch (error) {
          console.error('Error refreshing token:', error);
        }
      }, 14 * 60 * 1000);
    }

    return () => clearInterval(refreshInterval);
  }, [isAuthenticated]);

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated,
        isLoading,
        register,
        login,
        logout,
        refreshToken,
        joinFirm,
        leaveFirm,
      }}
    >
      {props.children}
    </AuthContext.Provider>
  );
}

export default AuthContextProvider;