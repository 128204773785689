import { Navigate } from "react-router-dom";
import { useContext } from "react";
import { AuthContext } from "../Provider/AuthContextProvider";
import FullLoading from "../FullLoading";
import { MeContext } from "../Provider/MeContextProvider";

type ProtectedProps = {
  component: React.ComponentType<any>;
};

const Protected = (props: ProtectedProps) => {
  const authContext = useContext(AuthContext);
  const meContext = useContext(MeContext);

  if (meContext.isLoading || authContext.isLoading) {
    return (<FullLoading />);
  }

  if (!authContext.isAuthenticated) {
    return (<Navigate to="/login" />);
  }

  return (
    <props.component />
  );
};

export default Protected;