import { Box } from "@mui/material";
import LoadingSvg from "../svg/LoadingSvg";

const FullLoading = () => {
  return (
    <Box sx={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'fixed',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      zIndex: 9999,
    }}>
      <LoadingSvg size={'25%'} />
    </Box>
  );
};

export default FullLoading;