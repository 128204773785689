import Titlebar from "../components/Titlebar";
import { ChangeEvent, useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { MetaContext } from "../components/Provider/MetaContextProvider";
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { Alert, Box, Button, IconButton, Stack, TextField, Typography } from "@mui/material";
import { SchochStack } from "../styles/styles";
import { AuthContext } from "../components/Provider/AuthContextProvider";

const LoginPage: React.FC = () => {
  const context = useContext(MetaContext);
  const authContext = useContext(AuthContext);
  const navigate = useNavigate();
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);

  const startLogin = async () => {
    try {
      context.setIsLoading(true);
      await authContext.login(username, password);

    } catch (error) {
      context.setAlertMessage("Ein Fehler ist aufgetreten.");
      context.setAlertSeverity("error");
    } finally {
      context.setIsLoading(false);
    }
  };

  const updateUsername = (evt: ChangeEvent<HTMLInputElement>) => {
    const newValue = evt.target && evt.target.value;
    setUsername(newValue);
  }

  const updatePassword = (evt: ChangeEvent<HTMLInputElement>) => {
    const newValue = evt.target && evt.target.value;
    setPassword(newValue);
  }

  if (authContext.isAuthenticated) {
    navigate('/overview');
  }

  return (
    <Stack>
      <Titlebar title="Login" iconName="" />
      <SchochStack sx={{ alignSelf: 'center' }}>
        <Box display='flex' flexDirection="row">
          <Typography sx={{width: 180, alignSelf: 'center'}}>Benutzername</Typography>
          <TextField
            sx={{ flex: 1 }}
            id="username"
            value={username}
            type="text"
            onChange={updateUsername}
            size="small"
            required
            autoComplete="username"
          />
        </Box>
        <Box display='flex' flexDirection="row">
          <Typography sx={{width: 180, alignSelf: 'center'}}>Passwort</Typography>
          <TextField
            sx={{ flex: 1 }}
            id="password"
            value={password}
            type={showPassword ? "text" : "password"}
            onChange={updatePassword}
            size="small"
            required
            autoComplete="current-password"
          />
          <IconButton
            sx={{padding: theme => theme.spacing(0), paddingLeft: 2, }}
            onClick={() => {setShowPassword(!showPassword)}}
          >
            {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon /> }
          </IconButton>
        </Box>
        <Box display='flex' flexDirection="row" gap={2}>
          <Button
            sx={{ width: '50%', backgroundColor: (theme) => theme.palette.background.paper, }}
            variant="outlined"
            onClick={() => navigate("/registration")}>
            Noch kein Konto
          </Button>
          <Button
            sx={{ width: '50%'}}
            variant="contained"
            onClick={startLogin}>
            Login
          </Button>
        </Box>
      </SchochStack>
    </Stack>
  );
};

export default LoginPage;