import { PostItDto } from "../dto/post-it-dto";
import authorizedApi from "./api";

const PostItAPI = {
  syncPostItQuarantine: async (): Promise<PostItDto[]> => {
    const response = await authorizedApi.get(`/post-it/sync`);
    return response.data as PostItDto[];
  },

  getPostIts: async (): Promise<PostItDto[]> => {
    const response = await authorizedApi.get(`/post-it`);
    return response.data as PostItDto[];
  },

  addPostIt: async (message: string): Promise<PostItDto> => {
    const response = await authorizedApi.post(`/post-it`, {text: message });
    return response.data as PostItDto;
  },

  updatePostIt: async (id: number, message: string): Promise<PostItDto> => {
    const response = await authorizedApi.put(`/post-it/${id}`, { text: message });
    return response.data as PostItDto;
  },

  deletePostIt: async (id: number) => {
    await authorizedApi.delete(`/post-it/${id}`);
  },
};

export default PostItAPI;