import Dialog from '@mui/material/Dialog';
import { SchochStickyRow, SlideUpTransition } from '../../styles/styles';
import { InputAdornment, Stack, TextField } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import MemberTable from '../MemberTable';
import { ChangeEvent, useCallback, useContext, useEffect, useState } from 'react';
import { User } from '../../models/user';
import UserFirmService from '../../services/user-firm-service';
import { MetaContext } from '../Provider/MetaContextProvider';
import DialogTitlebar from '../DialogTitlebar';
import debounce from 'lodash/debounce';

type AllMembersDialogProps = {
  isOpen: boolean;
  onClose: () => void;
}

const AllMembersDialog = (props: AllMembersDialogProps) => {
  const context = useContext(MetaContext);
  const [users, setUsers] = useState<User[]>([]);

  const handleSearchChange = async (evt: ChangeEvent<HTMLInputElement>) => {
    const newValue = evt.target && evt.target.value;
    debouncedSearch(newValue);
  };

  const debouncedSearch = useCallback(debounce(async (searchTerm?: string) => {
    await getUsersOfFirm(searchTerm);
  }, 400), []);

  const getUsersOfFirm = async (searchTerm?: string) => {
    const firmUsers = await context.handleAsyncOperation(
      () => UserFirmService.getAllUsers(searchTerm)
    );

    if (!!firmUsers) {
      setUsers(firmUsers);
    }
  };

  const removeUser = async (id: number) => {
    await context.handleAsyncOperation(
      () => UserFirmService.removeUser(id),
    );

    setUsers((prevUsers) => prevUsers.filter((user) => user.id !== id));
  };

  const toggleAdminPermission = async (user: User) => {
    const updatedUser = await context.handleAsyncOperation(
      () => UserFirmService.updateAdminPermission(user.id, !user.isAdmin)
    );

    if (!!updatedUser) {
      setUsers((prevUsers) => (prevUsers.map((user) => (user.id === updatedUser.id ? updatedUser : user))));
    }
  };

  useEffect(() => {
    if (props.isOpen) {
      getUsersOfFirm();
    }
  }, [props.isOpen]);

  return (
    <Dialog
      fullScreen
      open={props.isOpen}
      onClose={props.onClose}
      TransitionComponent={SlideUpTransition}
    >
      <DialogTitlebar title='Alle Mitglieder' onClose={props.onClose}/>
      <Stack sx={{ overflowY: 'auto', gap: 2, paddingTop: '0 !important' , padding: { sm: 2} }}>
        <SchochStickyRow sx={{ justifyContent: 'space-between', padding: 1, paddingLeft: 2, }}>
          <TextField
            variant='standard'
            label='Mitglieder durchsuchen'
            onChange={handleSearchChange}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
        </SchochStickyRow>
        <MemberTable />
      </Stack>
    </Dialog>
  );
};

export default AllMembersDialog;