import { Dialog, DialogTitle, DialogContent, Box, Avatar, Typography, Stack } from "@mui/material";
import { EntryButton } from "../../styles/styles";
import { useContext } from "react";
import { RapportContext } from "../Provider/RapportContextProvider";
import DynamicIcon from "../DynamicIcon";
import ItemEntry from "../../models/item-entry";
import ItemDialog from "./ItemDialog";

type EntryOverviewProps = {
  open: boolean;
  closeDialog: () => void;
};

const EntryOverview = (props: EntryOverviewProps) => {
  const rapportContext = useContext(RapportContext);

  const handleAddItem = (item: ItemEntry) => {
    rapportContext.setSelectedItem(item);
    props.closeDialog();
  };

  return (
    <Box>
      <Dialog open={props.open} onClose={props.closeDialog}>
        <DialogTitle>Fügen Sie ein Element hinzu:</DialogTitle>
        <DialogContent>
          <Box>
            <Stack>
            {rapportContext.rapport.entries.map(item => (
              <EntryButton
                key={item.id}
                disabled={item.isActive === true}
                onClick={() => handleAddItem(item)}
              >
                <Box sx={{ display: 'flex', flex: 1, flexDirection: 'row', alignItems: 'center', gap: 2, }}>
                  <Avatar
                    sx={{ backgroundColor: (theme) => item.isActive === true ? undefined : theme.palette.primary.main }}
                    variant='rounded'
                  >
                    <DynamicIcon iconName={item.iconName} />
                  </Avatar>
                  <Typography
                    sx={{
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      whiteSpace: 'normal',
                      textAlign: 'center',
                      color: (theme) =>
                        item.isActive === true
                          ? undefined
                          : theme.palette.text.primary
                    }}
                  >
                    {item.name}
                  </Typography>
                </Box>
              </EntryButton>
            ))}
            </Stack>
          </Box>
        </DialogContent>
      </Dialog>
    </Box>
  );
}

export default EntryOverview;