import { Box, Avatar, Stack, Typography, Divider } from "@mui/material";
import { SchochStack } from "../styles/styles";
import DynamicIcon from "./DynamicIcon";

type OverviewBoxProps = {
  avatarIcon: string;
  avatarColor: string;
  title: string;
  value: string;
};

const OverviewBox = (props: OverviewBoxProps) => {
  return (
    <SchochStack sx={{ display: 'flex', flexDirection: 'column', padding: 2, border: 1, gap: 2, }}>
      <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
        <Avatar
          sx={{ backgroundColor: props.avatarColor, boxShadow: 4, marginTop: -4, width: 50, height: 50, borderRadius: 2, }}
        >
          <DynamicIcon iconName={props.avatarIcon} />
        </Avatar>
        <Stack sx={{ alignItems: 'end' }}>
          <Typography>{props.title}</Typography>
          <Typography variant="h5" fontWeight='bold'>{props.value}</Typography>
        </Stack>
      </Box>
      <Divider variant="middle" />
      <Box sx={{ display: 'flex', flexDirection: 'row', gap: 1 }}>
        <Typography sx={{ color: theme => theme.palette.success.main, fontWeight: 'bold', }}>+55%</Typography>
        <Typography>verglichen mit letzter Woche</Typography>
      </Box>
    </SchochStack>
  );
};

export default OverviewBox;