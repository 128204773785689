import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Stack } from "@mui/material";
import Titlebar from "../components/Titlebar";
import { Rapport, defaultRapport } from "../models/rapport";
import RapportService from "../services/rapport-service";
import { MetaContext } from "../components/Provider/MetaContextProvider";
import PDFPreview from "../components/PDFPreview";

const RapportPage: React.FC = () => {
  const { id } = useParams();
  const context = useContext(MetaContext);
  const [rapport, setRapport] = useState<Rapport>(defaultRapport);

  const fetchRapport = async () => {
    if (!id) {
      return;
    }

    const data = await RapportService.getRapport(id);
    if (!!data) {
      setRapport(data);
    } else {
      context.setAlertMessage("Daten konnten nicht geladen werden.");
      context.setAlertSeverity("warning");
    }
  };

  useEffect(() => {
    try {
      fetchRapport();
    } catch (err) {
      context.setAlertMessage("Es ist ein Fehler aufgetreten.");
      context.setAlertSeverity("error");
    }
  }, []);

  return (
    <Stack>
      <Titlebar title="Rapport" iconName="" />
      <Stack sx={{ gap: 2, padding: { sm: 2 }, paddingTop: 0 }}>
        <PDFPreview rapport={rapport} />
      </Stack>
    </Stack>
  );
};

export default RapportPage;