import { Dialog, DialogTitle, DialogContent, DialogActions, TextField, Button, RadioGroup, FormControlLabel, Radio, Alert, Stack } from "@mui/material";
import { ChangeEvent, Dispatch, SetStateAction, useContext, useState } from "react";
import { MetaContext } from "../Provider/MetaContextProvider";
import RapportService from "../../services/rapport-service";
import { Rapport } from "../../models/rapport";
import { MeContext } from "../Provider/MeContextProvider";

type SendMessageDialogProps = {
  rapport: Rapport;
  setRapports: Dispatch<SetStateAction<Rapport[]>>;
  isOpen: boolean;
  onClose: () => void;
};

const SendMessageDialog = (props: SendMessageDialogProps) => {
  const context = useContext(MetaContext);
  const meContext = useContext(MeContext);
  const [message, setMessage] = useState("");

  const updatePostIt = async (evt: ChangeEvent<HTMLInputElement>) => {
    setMessage(evt.target.value);
  };

  const sendConfirmation = async () => {
    try {
      context.setIsLoading(true);
      const success: boolean = await RapportService.sendPostItQuarantine(props.rapport.id, message);
      const updatedRapport: Rapport = { ...props.rapport, receivedConfirmation: success };
      props.setRapports((prevRapports) =>
        prevRapports.map((prev) => (prev.id === updatedRapport.id ? updatedRapport : prev))
      );
      context.setAlertMessage("Es wurde eine Empfangsbestätigung an SchochAUFTRAG versendet.");
      context.setAlertSeverity("success");
    } catch (error) {
      context.setAlertMessage("Es ist ein Fehler beim Versenden der Empfangsbestätigung.");
      context.setAlertSeverity("error");
      console.error(error);
    } finally {
      context.setIsLoading(false);
    }
    props.onClose();
  };

  return (
    <Dialog
      open={props.isOpen}
      onClose={props.onClose}
    >
      <DialogTitle>Nachricht fürs Geschäft</DialogTitle>
      <DialogContent>
        <Stack gap={2}>
          {meContext.postIts.length > 0 ?
            <RadioGroup value={message} onChange={updatePostIt}>
              {meContext.postIts.map((postIt, index) => (
                <FormControlLabel key={index} value={postIt.message} control={<Radio />} label={postIt.message} />
              ))}
            </RadioGroup>
          : <Alert severity="info">Es wurden keine vorgefertigten Vorgang-Merkmale gefunden. Ein Admin kann unter 'Firma', 'Vorgang-Merkmale' neue Elemente hinzufügen.</Alert>}
          <TextField
            autoFocus
            multiline
            rows={5}
            fullWidth
            value={message}
            onChange={updatePostIt}
          />
        </Stack>
      </DialogContent>
      <DialogActions sx={{ paddingBottom: 2, paddingRight: 2 }}>
        <Button variant="contained" type="submit" onClick={sendConfirmation}>Absenden</Button>
      </DialogActions>
    </Dialog>
  );
};

export default SendMessageDialog;