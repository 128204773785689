import { useContext } from 'react';
import { Stack } from '@mui/material';
import Titlebar from '../components/Titlebar';
import FirmDetails from '../components/FirmDetails';
import NoFirmDetails from '../components/NoFirmDetails';
import { MeContext } from '../components/Provider/MeContextProvider';
import FirmContextProvider from '../components/Provider/FirmContextProvider';

const FirmPage: React.FC = () => {
  const meContext = useContext(MeContext);

  return (
    <Stack>
      <Titlebar title="Firma" iconName="Store" />
      { !!meContext.currentFirm ?
        <FirmContextProvider>
          <FirmDetails />
        </FirmContextProvider>
        : <NoFirmDetails /> }
    </Stack>
  );
}

export default FirmPage;