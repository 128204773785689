import { RapportItemDto, defaultRapportItem } from "../dto/rapport-item-dto";
import ItemEntry from "../models/item-entry";

// Info: These are columns of DB Table RapportItem
const allItems: ItemEntry[] = [
  {
    id: 1,
    name: "Auftragsdaten",
    beName: "rapportInfo",
    category: "Übersicht",
    iconName: "Info",
    isActive: false,
  },
  {
    id: 2,
    name: "Arbeitszeit",
    beName: "worktime",
    category: "Gesamtbetrag",
    iconName: "HourglassBottom",
    isActive: null,
  },
  {
    id: 3,
    name: "Material",
    beName: "material",
    category: "Gesamtbetrag",
    iconName: "ElectricalServices",
    isActive: null,
  },
  {
    id: 4,
    name: "Messungen",
    beName: "measurements",
    category: "Arbeit",
    iconName: "Straighten",
    isActive: false,
  },
  {
    id: 5,
    name: "Tätigkeiten",
    beName: "jobs",
    category: "Arbeit",
    iconName: "Handyman",
    isActive: false,
  },
  {
    id: 6,
    name: "Anhänge",
    beName: "attachments",
    category: "Sonstiges",
    iconName: "AttachFile",
    isActive: false,
  },
  {
    id: 7,
    name: "Unterschrift",
    beName: "signature",
    category: "Sonstiges",
    iconName: "HistoryEdu",
    isActive: false,
  },
];

const RapportItemHelper = {
  getAllItems: (): ItemEntry[] => {
    return allItems.filter(item => item.isActive !== null);
  },

  getItemByBeName: (beName: keyof RapportItemDto): ItemEntry | null => {
    const foundItem = allItems.find(item => item.beName === beName);
    return foundItem !== undefined ? foundItem : null;
  },

  itemEntriesFromRapportItem: (rapportItem: RapportItemDto): ItemEntry[] => {
    const entries: ItemEntry[] = [];
    
    allItems
    .filter(item => item.isActive !== null)
    .forEach(item => {
      if (rapportItem.hasOwnProperty(item.beName)) {
        entries.push({ ...item, isActive: rapportItem[item.beName] });
      }
    });
  
    return entries;
  },
  
  itemEntriesToRapportItem: (itemEntries: ItemEntry[]): RapportItemDto => {
    const rapportItem = defaultRapportItem;
  
    itemEntries
      .filter(item => item.isActive !== null)  
      .forEach(entry => {
        rapportItem[entry.beName] = entry.isActive;
      });
  
    return rapportItem;
  },
}

export default RapportItemHelper;