import { Box, Dialog, Stack } from "@mui/material";
import SchochButton from "../Buttons/SchochButton";
import AddIcon from '@mui/icons-material/Add';
import SyncIcon from '@mui/icons-material/Sync';
import { useContext, useState } from "react";
import { FirmContext } from "../Provider/FirmContextProvider";
import { ConfirmDialog } from "./Dialog";
import { MetaContext } from "../Provider/MetaContextProvider";
import PostItService from "../../services/post-it-service";
import { SchochStickyRow, SlideUpTransition } from "../../styles/styles";
import DialogTitlebar from "../DialogTitlebar";
import PostItTable from "../PostItTable";
import { MeContext } from "../Provider/MeContextProvider";

type PostItDialogProps = {
  isOpen: boolean;
  onClose: () => void;
};

const PostItDialog = (props: PostItDialogProps) => {
  const context = useContext(MetaContext);
  const meContext = useContext(MeContext);
  const firmContext = useContext(FirmContext);
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);

  const handleSyncButtonClick = async () => {
    if (meContext.postIts.length > 0) {
      setConfirmDialogOpen(true);
    } else {
      await syncPostIts();
    }
  };

  const syncPostIts = async () => {
    const data = await context.handleAsyncOperation(
      PostItService.syncPostItQuarantine
    );

    if (data !== null) {
      meContext.setPostIts(data);
    }
  };

  return (
    <Dialog
      fullScreen
      open={props.isOpen}
      onClose={props.onClose}
      TransitionComponent={SlideUpTransition}
    >
      <DialogTitlebar title='Vorgang-Merkmale' onClose={props.onClose} />
      <Stack sx={{ overflowY: 'auto', gap: 2, paddingTop: '0 !important' , padding: { sm: 2 } }}>
        <SchochStickyRow sx={{ alignItems: 'center', justifyContent: 'space-between', padding: 1, paddingLeft: 2, }}>
          <Box display='flex' flexDirection='row' gap={2}>
            <SchochButton
              icon={<AddIcon />}
              text='Neu'
              onClick={() => firmContext.setUpdatePostItIndex(-1)}
            />
            <SchochButton
              icon={<SyncIcon />}
              text='Sync..'
              onClick={handleSyncButtonClick}
            />
          </Box>
        </SchochStickyRow>
        <PostItTable />
      </Stack>
      <ConfirmDialog
        title="Mit SchochAuftrag synchronisieren?"
        description="Wollen Sie mit der Synchronisation fortfahren? Falls Sie hier Änderungen an den Vorgang-Merkmalen vorgenommen haben, werden diese überschrieben und diejenigen von SchochAuftrag übernommen."
        isOpen={confirmDialogOpen}
        onClose={() => setConfirmDialogOpen(false)}
        onConfirm={() => { syncPostIts(); setConfirmDialogOpen(false);}}
      />
    </Dialog>
  );
};

export default PostItDialog;