export const base64ToBlob = (base64String: string, type: string): Blob => {
  const binaryString = atob(base64String);
  const buffer = new ArrayBuffer(binaryString.length);
  const uint8Array = new Uint8Array(buffer);
  for (let i = 0; i < binaryString.length; i++) {
    uint8Array[i] = binaryString.charCodeAt(i);
  }

  return new Blob([buffer], { type: type });
};

export const formatHours = (hours: number): string => {
  if (hours === 0) {
    return '(Keine Zeit)';
  }

  return `${hours.toFixed(2)} h`;
};

export const roundCHF = (amount: number): number => {
  return Math.round(amount * 20) / 20;
};
