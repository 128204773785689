import { EldasDto } from "../dto/catalogs/eldas-dto";
import { NpkDto } from "../dto/catalogs/npk-dto";
import { SteigerDto } from "../dto/catalogs/steiger-dto";
import authorizedApi from "./api";

const MaterialAPI = {
  getMaterialWithPrice: async (id: number, catalog: string): Promise<SteigerDto | EldasDto | NpkDto> => {
    let queryParams: any = {};
    if (catalog !== undefined) {
      queryParams.catalog = catalog;
    }

    const response = await authorizedApi.get(`material/${id}`, { params: queryParams });
    return response.data;
  },

  searchSteigerMaterial: async (id?: number, page?: number, pageSize?: number, search?: string): Promise<SteigerDto[]> => {
    let queryParams: any = {};
    if (id !== undefined) {
      queryParams.id = id;
    }
    if (page !== undefined) {
      queryParams.page = page;
    }
    if (pageSize !== undefined) {
      queryParams.pageSize = pageSize;
    }
    if (search !== undefined) {
      queryParams.search = search;
    }

    const response = await authorizedApi.get('/material/steiger/search', { params: queryParams });
    return response.data as SteigerDto[];
  },

  searchEldasMaterial: async (page?: number, pageSize?: number, search?: string): Promise<EldasDto[]> => {
    let queryParams: any = {};
    if (page !== undefined) {
      queryParams.page = page;
    }
    if (pageSize !== undefined) {
      queryParams.pageSize = pageSize;
    }
    if (search !== undefined) {
      queryParams.search = search;
    }

    const response = await authorizedApi.get('/material/eldas/search', { params: queryParams });
    return response.data as EldasDto[];
  },

  searchNpkMaterial: async (id?: number, page?: number, pageSize?: number, search?: string): Promise<NpkDto[]> => {
    let queryParams: any = {};
    if (id !== undefined) {
      queryParams.id = id;
    }
    if (page !== undefined) {
      queryParams.page = page;
    }
    if (pageSize !== undefined) {
      queryParams.pageSize = pageSize;
    }
    if (search !== undefined) {
      queryParams.search = search;
    }

    const response = await authorizedApi.get('/material/npk/search', { params: queryParams });
    return response.data as NpkDto[];
  },

  loadPrices: async (): Promise<string> => {
    const response = await authorizedApi.post('/material/load-prices');
    return response.data as string;
  },

  cancelPriceLoading: async (): Promise<boolean> => {
    const response = await authorizedApi.post('/material/cancel');
    return response.data as boolean;
  },
};

export default MaterialAPI;