import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import MetaContextProvider from './components/Provider/MetaContextProvider';
import MeContextProvider from './components/Provider/MeContextProvider';
import * as serviceWorker from './serviceWorkerRegistration';
import AuthContextProvider from './components/Provider/AuthContextProvider';

const domNode = document.getElementById('root') as Element;
const root = createRoot(domNode);
root.render(
  <BrowserRouter>
    <MetaContextProvider>
      <AuthContextProvider>
        <MeContextProvider>
          <App />
        </MeContextProvider>
      </AuthContextProvider>
    </MetaContextProvider>
  </BrowserRouter>
);

serviceWorker.register();