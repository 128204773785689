import Typography from '@mui/material/Typography';
import { Alert, Box, Button, Divider, IconButton, InputAdornment, MenuItem, Stack, TextField, Tooltip, useMediaQuery, useTheme } from '@mui/material';
import { ChangeEvent, useCallback, useContext, useEffect, useState } from 'react';
import { SchochStack } from '../../styles/styles';
import SearchIcon from '@mui/icons-material/Search';
import { RapportContext } from '../Provider/RapportContextProvider';
import { MeContext } from '../Provider/MeContextProvider';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import DeleteIcon from '@mui/icons-material/Delete';
import SteigerCatalog from '../SteigerCatalog';
import { CatalogType } from '../../models/catalog-type';
import { debounce } from 'lodash';
import EldasCatalog from '../EldasCatalog';
import NpkCatalog from '../NpkCatalog';
import { Material } from '../../models/material';
import { roundCHF } from '../../helpers/utils';
import { ItemProps } from '../Dialogs/ItemDialog';

const MaterialItem = (props: ItemProps) => {
  const rapportContext = useContext(RapportContext);
  const meContext = useContext(MeContext);
  const [openAddedMaterial, setOpenAddedMaterial] = useState(false);
  const [searchValue, setSearchValue] = useState("");

  const theme = useTheme();
  const isXSScreen = useMediaQuery(theme.breakpoints.only('xs'));

  const handleAmountChange = (evt: ChangeEvent<HTMLInputElement>, index: number) => {
    const updatedMaterials = rapportContext.rapport.materials.map((material, i) => 
      i === index ? { ...material, amount: evt.target.value } : material
    );
    rapportContext.setRapport(prev => ({ ...prev, materials: updatedMaterials }));
  };

  const handlePriceChange = (evt: ChangeEvent<HTMLInputElement>, index: number) => {
    const updatedMaterials = rapportContext.rapport.materials.map((material, i) => 
      i === index ? { ...material, price: evt.target.value } : material
    );
    rapportContext.setRapport(prev => ({ ...prev, materials: updatedMaterials }));
  };

  const handleCatalogChange = (evt: ChangeEvent<HTMLInputElement>) => {
    meContext.setCurrentCatalog(evt.target.value as (CatalogType | ""));
  };

  const handleDeleteClick = (index: number) => {
    const updatedMaterials = rapportContext.rapport.materials.filter((_, i) => i !== index);
    rapportContext.setRapport(prev => ({ ...prev, materials: updatedMaterials }));
  };

  const debouncedSetSearchValue = useCallback(
    debounce((value) => setSearchValue(value), 400), []
  );

  const handleSearchChange = async (evt: ChangeEvent<HTMLInputElement>) => {
    debouncedSetSearchValue(evt.target.value);
  };

  const formatId = (material: Material) => {
    const id = material.displayedProductId;
    if (id.length !== 9) {
      return id;
    }

    if (material.catalogType === "npk") {
      return `${id.slice(0, 3)}.${id.slice(3, 6)}.${id.slice(6, 9)}`;
    }

    return `${id.slice(0, 3)} ${id.slice(3, 6)} ${id.slice(6, 9)}`;
  };

  useEffect(() => {
    const isFilled = rapportContext.rapport.materials.length > 0;
    props.updateIsFilled(isFilled);
  }, [rapportContext.rapport.materials]);

  const displayNoData = () => {
    return (
      <Stack marginTop={2}>
        <Alert severity='info' sx={{ alignItems: 'center'}}>
          <Box sx={{ display: 'flex', flexDirection: isXSScreen ? 'column' : 'row', gap: 2, alignItems: isXSScreen ? 'none' : 'center' }}>
            <Typography variant='inherit'>Kein Katalog ausgewählt.</Typography>
            <Button onClick={meContext.syncCatalogLicences} variant='contained' color='info'>Lizenzen prüfen</Button>
          </Box>
        </Alert>
      </Stack>
    );
  };

  const renderCatalog = () => {
    switch (meContext.currentCatalog) {
      case "steiger":
        return <SteigerCatalog isXSScreen={isXSScreen} searchValue={searchValue} />;
      case "eldas":
        return <EldasCatalog isXSScreen={isXSScreen} searchValue={searchValue} />;
      case "npk":
        return <NpkCatalog isXSScreen={isXSScreen} searchValue={searchValue} />;
      default:
        return <>{displayNoData()}</>;
    }
  };

  const searchBar = () => {
    return (
      <SchochStack sx={{ flexDirection: { xs: 'column', sm: 'row' }, alignItems: 'space-between', justifyContent:'space-between' }}>
        <TextField
          variant='standard'
          label='Katalog durchsuchen'
          disabled={meContext.currentCatalog === ""}
          onChange={handleSearchChange}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <IconButton sx={{ padding: 0, }}>
                  <SearchIcon />
                </IconButton>
              </InputAdornment>
            ),
          }}
          sx={{ alignSelf: { xs: 'stretch', sm: 'auto' } }}
        />
        <TextField
          variant='standard'
          label='Katalog auswählen'
          select
          value={meContext.currentCatalog}
          onChange={handleCatalogChange}
          sx={{ width: 160 }}
        >
          <MenuItem value="steiger" disabled={!meContext.currentFirm?.hasSteiger}>Steiger</MenuItem>
          <MenuItem value="eldas" disabled={!meContext.currentFirm?.hasEldas}>Eldas</MenuItem>
          <MenuItem value="npk" disabled={!meContext.currentFirm?.hasNpk}>NPK</MenuItem>
        </TextField>
      </SchochStack>
    );
  };

  const addedMaterialItem = (material: Material, index: number) => {
    return (
      <Box sx={{ display: 'flex', flexDirection: rapportContext.rapport.showPrices || isXSScreen ? 'column' : 'row', justifyContent: 'space-between', gap: 2, paddingTop: 2, }} >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            gap: 2,
          }}
        >
          <Typography>{formatId(material)}</Typography>
          <Tooltip title={material.name} >
            <Typography
              sx={{
                fontWeight: 'bold',
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
                overflow: 'hidden',
                flex: 1,
                minWidth: 0,
              }}
            >
              {material.name}
            </Typography>
          </Tooltip>
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'row', gap: 2, justifyContent: 'space-between' }}>
          <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: (rapportContext.rapport.showPrices || isXSScreen) ? 2 : 0 }}>
            <TextField
              type='number'
              label='Einzelpreis'
              value={material.price}
              onChange={(event: ChangeEvent<HTMLInputElement>) => handlePriceChange(event, index)}
              size='small'
              sx={{ display: (rapportContext.rapport.showPrices) ? 'flex' : 'none', width: 90 }}
            />
            <TextField
              type='number'
              label='Menge'
              value={material.amount}
              onChange={(event: ChangeEvent<HTMLInputElement>) => handleAmountChange(event, index)}
              size='small'
              sx={{ width: 65 }}
            />
            <Typography>{material.unit}</Typography>
          </Box>

          <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 1 }}>
            <Typography sx={{ display: rapportContext.rapport.showPrices ? 'flex' : 'none', fontWeight: 'bold' }}>{roundCHF(Number(material.amount) * Number(material.price)).toFixed(2)} CHF</Typography>
            <IconButton onClick={() => handleDeleteClick(index)}>
              <DeleteIcon />
            </IconButton>
          </Box>
        </Box>
      </Box>
    );
  };

  return (
    <Box>
      <Stack marginTop={2} gap={2}>
        {searchBar()}
        <SchochStack>
          <Box
            sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', flex: 1 }}
            onClick={() => setOpenAddedMaterial(prev => !prev)}
          >
            <Typography variant='h6'>Hinzugefügtes Material ({rapportContext.rapport.materials.length})</Typography>
            {openAddedMaterial ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </Box>
          {openAddedMaterial && <Stack gap={1}>
            {rapportContext.rapport.materials.map((material, index) => (
              <Box key={index}>
                {index !== 0 && <Divider />}
                {addedMaterialItem(material, index)}
              </Box>
            ))}
          </Stack>}
        </SchochStack>
        {renderCatalog()}
      </Stack>
    </Box>
  );
};

export default MaterialItem;