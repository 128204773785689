import Titlebar from "../components/Titlebar";
import { Alert, Box, Link, Skeleton, Stack, Typography, useTheme } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { MetaContext } from "../components/Provider/MetaContextProvider";
import RapportService from "../services/rapport-service";
import { Rapport } from "../models/rapport";
import OverviewRapport from "../components/OverviewRapport";
import { MeContext } from "../components/Provider/MeContextProvider";
import { Row, SchochStack } from "../styles/styles";
import PdfSvgIcon from "../svg/PdfSvgIcon";
import NoFirmAlert from "../components/NoFirmAlert";
import OverviewBox from "../components/OverviewBox";
import { PriceTotal, defaultPriceTotal } from "../models/price-total";

const OverviewPage: React.FC = () => {
  const theme = useTheme();
  const context = useContext(MetaContext);
  const meContext = useContext(MeContext);
  const [spotlight, setSpotlight] = useState<Rapport | null>(null);
  const [rapportCount, setRapportCount] = useState(0);
  const [total, setTotal] = useState<PriceTotal>(defaultPriceTotal);

  const fetchSpotlight = async () => {
    try {
      context.setIsLoading(true);
      const data = await RapportService.getSpotlight();
      setSpotlight(data);
    } catch (error) {
      context.setAlertMessage("Ein Fehler ist aufgetreten.");
      context.setAlertSeverity("error");
      console.error(error);
    } finally {
      context.setIsLoading(false);
    }
  };

  const fetchRapportCount = async () => {
    try {
      context.setIsLoading(true);
      const data = await RapportService.getRapportCount();
      setRapportCount(data);
    } catch (error) {
      context.setAlertMessage("Ein Fehler ist aufgetreten.");
      context.setAlertSeverity("error");
      console.error(error);
    } finally {
      context.setIsLoading(false);
    }
  };

  const fetchPriceTotal = async (id: string) => {
    try {
      context.setIsLoading(true);
      const data = await RapportService.calculateRapportTotal(id);
      setTotal(data);
    } catch (error) {
      context.setAlertMessage("Ein Fehler ist aufgetreten.");
      context.setAlertSeverity("error");
      console.error(error);
    } finally {
      context.setIsLoading(false);
    }
  };

  useEffect(() => {
    if (!!spotlight?.id && spotlight.showPrices) {
      fetchPriceTotal(spotlight.id);
    }
  }, [spotlight]);

  useEffect(() => {
    if (meContext.currentFirm !== null) {
      fetchSpotlight();
      fetchRapportCount();
    }
  }, [meContext.currentFirm]);

  const noFirmDisplay = () => {
    return (
      <Stack sx={{ padding: { sm: 2 }, gap: 2 }}>
        <NoFirmAlert />
        <SchochStack>
          <Typography variant="h6">Infos zu SchochAUFTRAG 'Alfred'</Typography>
          <Box display='flex' flexDirection='row' gap={2}>
            <Link sx={{ alignSelf: 'center', }} href="/pdfs/schoch-auftrag.pdf" download='schoch-auftrag.pdf'>
              <PdfSvgIcon size={60} />
            </Link>
            <Typography alignSelf='center' textAlign='center'>Broschüre herunterladen</Typography>
          </Box>
        </SchochStack>
      </Stack>
    );
  };

  const newFirmDisplay = () => {
    return (
      <Stack gap={4}>
        <Box sx={{ display: 'flex', flexDirection: { xs: 'column', sm: 'row' }, gap: { xs: 4, sm: 2 }, paddingTop: { xs: 2, sm: 0 } }}>
          <OverviewBox title="Meine Rapporte" value={rapportCount.toString()} avatarIcon="Description" avatarColor={theme.palette.secondary.main} />
          <OverviewBox title="Mitarbeiter" value={meContext.firmUsers?.length.toString() ?? "0"} avatarIcon="Face" avatarColor={theme.palette.success.main} />
        </Box>
        <Box sx={{ display: 'flex', flexDirection: { xs: 'column', sm: 'row' }, gap: { xs: 4, sm: 2 } }}>
          <OverviewBox title="Meine Rapporte" value={"1"} avatarIcon="Description" avatarColor={theme.palette.info.main} />
          <OverviewBox title="Mitarbeiter" value={meContext.firmUsers?.length.toString() ?? "0"} avatarIcon="Face" avatarColor={theme.palette.warning.main} />
        </Box>
        {spotlight !== null ? <OverviewRapport rapport={spotlight} priceTotal={total} /> : <Alert severity="info">Sie haben keinen ausstehenden Rapport.</Alert>}
      </Stack>
    );
  };

  const skeletonDisplay = () => {
    return (
      <Stack gap={4}>
        <Row sx={{ gap: 2, }}>
          <Skeleton variant="rectangular" height={150} sx={{ flex: 1 }} />
          <Skeleton variant="rectangular" height={150} sx={{ flex: 1 }} />
        </Row>
        <Row sx={{ gap: 2, }}>
          <Skeleton variant="rectangular" height={150} sx={{ flex: 1 }} />
          <Skeleton variant="rectangular" height={150} sx={{ flex: 1 }} />
        </Row>
        <Row>
          <Skeleton variant="rectangular" height={320} sx={{ flex: 1 }} />
        </Row>
      </Stack>
    );
  };

  return (
    <Stack>
      <Titlebar title="Übersicht" iconName="Apps" />
        <Stack sx={{ padding: { xs: 0, sm: 2 }}}>
          {context.isLoading && skeletonDisplay()}
          {!context.isLoading && meContext.currentFirm === null && noFirmDisplay()}
          {!context.isLoading && meContext.currentFirm !== null && newFirmDisplay()}
        </Stack>
    </Stack>
  );
};

export default OverviewPage;