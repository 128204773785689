import { FirmDto } from "../dto/firm-dto";
import { CreateUpdateFirmDto } from "../dto/create-update-firm-dto";
import { Firm } from "../models/firm";
import { CreateUpdateFirm } from "../models/create-update-firm";

const FirmHelper = {
  firmFromDto: (firmDto: FirmDto): Firm => {
    return {
      firmLicence: firmDto.firmLicence,
      firmName: firmDto.firmName,
      weeklyTarget: firmDto.weeklyTarget,
      hasSteiger: firmDto.hasSteiger,
      hasEldas: firmDto.hasEldas,
      hasNpk: firmDto.hasNpk,
      latestPriceUpdate: (firmDto.latestPriceUpdate !== null) ? new Date(firmDto.latestPriceUpdate) : null,
    };
  },

  createUpdateFirmToDto: (createUpdateFirm: CreateUpdateFirm): CreateUpdateFirmDto => {
    const weeklyTarget = Number.parseFloat(createUpdateFirm.weeklyTarget);
    return {
      firmName: createUpdateFirm.firmName,
      weeklyTarget: !isNaN(weeklyTarget) ? weeklyTarget : 0,
    };
  },
};

export default FirmHelper;