import { createContext, useState, ReactNode, useEffect, SetStateAction, Dispatch } from "react";
import UserService from "../../services/user-service";

export type Severity = 'success' | 'info' | 'warning' | 'error';

type MetaContextType = {
  handleAsyncOperation: (operation: () => Promise<any>) => Promise<any>,
  isLoading: boolean,
  setIsLoading: Dispatch<SetStateAction<boolean>>,
  isDarkTheme: boolean,
  setDarkTheme: (darkTheme: boolean) => void,
  alertMessage: string | null,
  setAlertMessage: Dispatch<SetStateAction<string | null>>,
  alertSeverity: Severity,
  setAlertSeverity: Dispatch<SetStateAction<Severity>>,
  selectedRapportIds: string[],
  setSelectedRapportIds: Dispatch<SetStateAction<string[]>>,
};

export const MetaContext = createContext<MetaContextType>({
  handleAsyncOperation: async () => {},
  isLoading: false,
  setIsLoading: () => {},
  isDarkTheme: false,
  setDarkTheme: () => {},
  alertMessage: null,
  setAlertMessage: () => {},
  alertSeverity: 'success',
  setAlertSeverity: () => {},
  selectedRapportIds: [],
  setSelectedRapportIds: () => {},
});

type MetaContextProviderProps = {
  children: ReactNode;
};

const MetaContextProvider = (props: MetaContextProviderProps) => {
  const [isLoading, setIsLoading] = useState(true);
  const [isDarkTheme, setIsDarkTheme] = useState(false);
  const [selectedRapportIds, setSelectedRapportIds] = useState<string[]>([]);
  const [alertMessage, setAlertMessage] = useState<string | null>(null);
  const [alertSeverity, setAlertSeverity] = useState<Severity>('success');

  const handleAsyncOperation = async (operation: () => Promise<any>): Promise<any> => {
    try {
      setIsLoading(true);
      return await operation();
    } catch (error) {
      setAlertMessage("Ein Fehler ist aufgetreten.");
      setAlertSeverity("error");
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const setDarkTheme = (darkTheme: boolean) => {
    UserService.setDarkMode(darkTheme);
    setIsDarkTheme(isDarkTheme);
  };

  useEffect(() => {
    const loadContext = async () => {
      try {
        setIsDarkTheme(UserService.getDarkMode());
      } catch (error) {
        setAlertMessage("Es konnte keine Verbindung hergestellt werden.");
        setAlertSeverity("error");
      } finally {
        setIsLoading(false);
      }
    };

    loadContext(); 
  }, []);

  return (
    <MetaContext.Provider
      value={{
        handleAsyncOperation,
        isLoading,
        setIsLoading,
        isDarkTheme,
        setDarkTheme,
        alertMessage,
        setAlertMessage,
        alertSeverity,
        setAlertSeverity,
        selectedRapportIds,
        setSelectedRapportIds,
      }}
    >
      {props.children}
    </MetaContext.Provider>
  );
}

export default MetaContextProvider;
