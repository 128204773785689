import UserAPI from "../api/user-api";
import { CredentialsDto } from "../dto/users/credentials-dto";
import { UpdateUserDto } from "../dto/users/update-user-dto";
import LocalStorageHelper from "../helpers/local-storage-helper";
import UserHelper from "../helpers/user-helper";
import { User } from "../models/user";

const UserService = {
  getCurrentUser: async (): Promise<User> => {
    const userDto = await UserAPI.getCurrentUser();
    return UserHelper.userFromDto(userDto);
  },

  updateUser: async (firstName: string, lastName: string, username: string): Promise<number> => {
    const user = {firstName, lastName, username} as UpdateUserDto;
    return await UserAPI.updateUser(user);
  },

  deleteUser: async () => {
    await UserAPI.deleteUser();
  },

  usernameExists: async (username: string): Promise<boolean> => {
    return await UserAPI.usernameExists(username);
  },

  updatePassword: async (username: string, password: string): Promise<number> => {
    const credentialsDto: CredentialsDto = { username, password };
    return await UserAPI.updatePassword(credentialsDto);
  },

  setDarkMode: (isDarkMode: boolean) => {
    LocalStorageHelper.setDarkMode(isDarkMode);
  },
  
  getDarkMode: (): boolean => {
    return LocalStorageHelper.getDarkMode();
  },
};

export default UserService;