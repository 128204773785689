import { PostItDto } from "../dto/post-it-dto";
import { PostIt } from "../models/post-it";

const PostItHelper = {
  postItFromDto: (postItDto: PostItDto): PostIt => {
    return {
      id: postItDto.id,
      firmLicence: postItDto.firmLicence,
      message: postItDto.message,
    };
  },
};

export default PostItHelper;