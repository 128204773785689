import { Address, defaultAddress } from "./address";
import { Comments } from "./comment";
import ItemEntry from "./item-entry";
import { defaultJob, Job } from "./job";
import { defaultMeasurements, Measurements } from "./measurements";
import { User } from "./user";
import { Worktime } from "./worktime";
import { Material } from "./material";

export type Rapport = {
	id: string;
	emilId: number;
	projectId: string;
	betreff: string;
	dueDate: Date | null;
	status: string;
	jobDone: boolean;
	billed: boolean;
	isDeleted: boolean;
	isArchived: boolean;
	description: string;
	receivedConfirmation: boolean | null;
	assigneeId: number | null;
	jobs: Job;
	itemId: number;
	measurements: Measurements;
	customers: Address[];
	entries: ItemEntry[];
	comments: Comments[];
	worktimes: Worktime[];
	materials: Material[];
	reporter: string;
	phoneNr: string;
	cost: string;
	counter: string;
	more: string;
	creator: User | null;
	createDate: Date | null;
	vat: number;
	discount: number;
	showPrices: boolean;
};

export const defaultRapport: Rapport = {
	id: '',
	emilId: 0,
	projectId: '',
	betreff: '',
	dueDate: new Date(),
	status: '',
	jobDone: false,
	billed: false,
	isDeleted: false,
	isArchived: false,
	description: '',
	receivedConfirmation: null,
	assigneeId: null,
	jobs: defaultJob,
	itemId: 0,
	measurements: defaultMeasurements,
	customers: Array.from({ length: 3 }, () => ({ ...defaultAddress })),
	comments: [],
	worktimes: [],
	entries: [],
	materials: [],
	reporter: '',
	phoneNr: '',
	cost: '',
	counter: '',
	more: '',
	creator: null,
	createDate: new Date(),
	vat: 8.1,
	discount: 0,
	showPrices: false,
};