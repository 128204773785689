import { format } from "date-fns";
import { WorktimeDayDto } from "../dto/worktime-day-dto";
import { WorktimeDto } from "../dto/worktime-dto";
import { Worktime } from "../models/worktime";
import { WorktimeDay } from "../models/worktime-day";
import UserHelper from "./user-helper";
import { WorktimeWeek } from "../models/worktime-week";
import { WorktimeWeekDto } from "../dto/worktime-week-dto";

const WorktimeHelper = {
  worktimeToDto: (worktime: Worktime): WorktimeDto => {
    return {
      id: worktime.id,
      date: format(worktime.date, 'yyyy-MM-dd'),
      user: (worktime.user !== null) ? UserHelper.userToDto(worktime.user) : null,
      timeInHours: Number(worktime.timeInHours),
      type: worktime.type,
      description: worktime.description,
      isRemoved: worktime.isRemoved,
      isChecked: worktime.isChecked,
      copyId: worktime.copyId,
      username: worktime.username,
      ic: worktime.ic,
      price: Number(worktime.price),
      productId: worktime.productId,
    };
  },

  worktimeFromDto: (worktimeDto: WorktimeDto): Worktime => {
    return {
      id: worktimeDto.id,
      date: new Date(worktimeDto.date),
      user: (worktimeDto.user !== null) ? UserHelper.userFromDto(worktimeDto.user) : null,
      timeInHours: worktimeDto.timeInHours.toString(),
      type: worktimeDto.type,
      description: worktimeDto.description,
      isRemoved: worktimeDto.isRemoved,
      isChecked: worktimeDto.isChecked,
      copyId: worktimeDto.copyId,
      username: worktimeDto.username,
      ic: worktimeDto.ic,
      price: worktimeDto.price.toString(),
      productId: worktimeDto.productId,
    };
  },

  worktimeDayFromDto: (worktimeDay: WorktimeDayDto): WorktimeDay => {
    return {
      date: new Date(worktimeDay.date),
      timeInMinutes: worktimeDay.timeInMinutes,
      workInMinutes: worktimeDay.workInMinutes,
      vacationInMinutes: worktimeDay.vacationInMinutes,
      compensationInMinutes: worktimeDay.compensationInMinutes,
      unpaidInMinutes: worktimeDay.unpaidInMinutes,
      sicknessInMinutes: worktimeDay.sicknessInMinutes,
      worktimes: worktimeDay.worktimes.map(x => WorktimeHelper.worktimeFromDto(x)),
    };
  },

  worktimeWeekFromDto: (worktimeWeek: WorktimeWeekDto): WorktimeWeek => {
    return {
      startOfWeek: new Date(worktimeWeek.startOfWeek),
      total: worktimeWeek.total,
      days: worktimeWeek.days.map(x => WorktimeHelper.worktimeDayFromDto(x)),
    };
  },
}

export default WorktimeHelper;