import { Button, Stack } from '@mui/material';
import { useContext, useEffect, useRef, useState } from 'react';
import { RapportContext } from '../Provider/RapportContextProvider';
import { ItemProps } from '../Dialogs/ItemDialog';
import AttachmentService from '../../services/attachment-service';
import { MetaContext } from '../Provider/MetaContextProvider';
import { Signature } from '../../models/signature';

const SignatureItem = (props: ItemProps) => {
  const context = useContext(MetaContext);
  const rapportContext = useContext(RapportContext)
  const canvasRef = useRef<HTMLCanvasElement | null>(null);
  const [isDrawing, setIsDrawing] = useState(false);

  const fetchSignature = async () => {
    if (!!!rapportContext.rapportId || rapportContext.signature !== null) {
      return;
    }

    const data = await context.handleAsyncOperation(
      () => AttachmentService.getSignature(rapportContext.rapportId)
    );

    rapportContext.setSignature(data);
  };

  const getPosition = (e: React.MouseEvent<HTMLCanvasElement> | React.TouchEvent<HTMLCanvasElement>): { x: number, y: number } => {
    const canvas = canvasRef.current;
    if (!canvas) return { x: 0, y: 0 };

    const rect = e.currentTarget.getBoundingClientRect();
    let clientX, clientY;

    if (e.type.startsWith('mouse')) {
      const mouseEvent = e as React.MouseEvent<HTMLCanvasElement>;
      clientX = mouseEvent.clientX;
      clientY = mouseEvent.clientY;
    } else {
      const touchEvent = e as React.TouchEvent<HTMLCanvasElement>;
      clientX = touchEvent.touches[0].clientX;
      clientY = touchEvent.touches[0].clientY;
    }

    return {
      x: clientX - rect.left,
      y: clientY - rect.top,
    };
  };

  const startDrawing = (event: React.MouseEvent<HTMLCanvasElement> | React.TouchEvent<HTMLCanvasElement>) => {
    setIsDrawing(true);
    const canvas = canvasRef.current?.getContext('2d');
    if (!canvas) return;

    const { x, y } = getPosition(event);
    canvas.beginPath();
    canvas.moveTo(x, y);
  };

  const continueDrawing = (event: React.MouseEvent<HTMLCanvasElement> | React.TouchEvent<HTMLCanvasElement>) => {
    if (!isDrawing) return;

    const canvas = canvasRef.current?.getContext('2d');
    if (!canvas) return;

    const { x, y } = getPosition(event);
    canvas.lineTo(x, y);
    canvas.stroke();
  };

  const endDrawing = () => {
    setIsDrawing(false);
    const canvas = canvasRef.current?.getContext('2d');
    if (canvas) {
      canvas.closePath();
    }

    saveCanvas();
  };

  const clearCanvas = async () => {
    const current = canvasRef.current;
    if (!current) return;

    const canvas = current.getContext('2d');
    if (canvas) {
      canvas.clearRect(0, 0, current.width, current.height);
    }

    const id: number = rapportContext.signature?.id ?? 0;  
    if (id !== 0) {
      await context.handleAsyncOperation(
        () => AttachmentService.deleteAttachment(id)
      );
    }
    
    rapportContext.setSignature(null);
  };

  const loadCanvas = () => {
    if (!!rapportContext.signature) {
      const canvas = canvasRef.current?.getContext('2d');
      if (canvas) {
        const image = new Image();
        image.onload = () => {
          canvas.clearRect(0, 0, canvasRef.current!.width, canvasRef.current!.height); // Clear the canvas before drawing
          canvas.drawImage(image, 0, 0);
        };
        image.src = "data:image/png;base64," + rapportContext.signature.content;
      }
    }
  };

  const saveCanvas = () => {
    const canvas = canvasRef.current;
    if (!!!canvas) return;
    const contents = canvas.toDataURL('image/png');

    const newSignature: Signature = {
      id: rapportContext.signature?.id ?? 0,
      content: contents.split(',')[1],
    };

    rapportContext.setSignature(newSignature);
  };

  const onResize = () => {
    const current = canvasRef.current;
    if (!current) return;

    current.width = current.offsetWidth;
    current.height = current.offsetHeight;

    const canvas = current.getContext('2d');
    if (canvas) {
      canvas.lineJoin = 'round';
      canvas.lineCap = 'round';
      canvas.fillStyle = 'white';
      canvas.fillRect(0, 0, current.width, current.height);
    }

    loadCanvas();
  };

  useEffect(() => {
    onResize();
    window.addEventListener('resize', onResize);

    return () => {
      window.removeEventListener('resize', onResize);
    };
  }, []);

  useEffect(() => {
    loadCanvas();

    const isFilled = rapportContext.signature !== null;
    props.updateIsFilled(isFilled);
  }, [rapportContext.signature]);

  useEffect(() => {
    fetchSignature();
    return () => {
      saveCanvas();
    };
  }, []);

  return (
    <Stack marginTop={2} gap={2}>
      <canvas
        ref={canvasRef}
        style={{ border: '1px solid #000' }}
        onMouseDown={startDrawing}
        onTouchStart={startDrawing}
        onMouseMove={continueDrawing}
        onTouchMove={continueDrawing}
        onMouseUp={endDrawing}
        onTouchEnd={endDrawing}
      />
      <Button variant='contained' sx={{ width: 200, alignSelf: 'center' }} onClick={clearCanvas}>
        Zurücksetzen
      </Button>
    </Stack>
  );
};

export default SignatureItem;
