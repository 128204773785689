import { RapportFilter } from "../models/rapport-filter";

const LocalStorageHelper = {
  getRefreshToken: (): string | null => {
    return localStorage.getItem('Refresh');
  },

  storeRefreshToken: (refreshToken: string) => {
    localStorage.setItem('Refresh', refreshToken);
  },

  removeRefreshToken: () => {
    localStorage.removeItem('Refresh');
  },

  getSessionToken: (): string | null => {
    return localStorage.getItem('Authorization');
  },

  storeSessionToken: (token: string) => {
    localStorage.setItem('Authorization', token);
  },

  removeSessionToken: () => {
    localStorage.removeItem('Authorization');
  },

  setDarkMode: (isDarkMode: boolean) => {
    localStorage.setItem('isDarkMode', JSON.stringify(isDarkMode));
  },

  getDarkMode: (): boolean => {
    const darkModeSetting = localStorage.getItem('isDarkMode');
    return darkModeSetting ? JSON.parse(darkModeSetting) : false;
  },

  setRapportFilter: (rapportFilter: RapportFilter) => {
    localStorage.setItem('rapportFilter', JSON.stringify(rapportFilter));
  },
  
  getRapportFilter: (): RapportFilter | null => {
    const rapportFilters = localStorage.getItem('rapportFilter');
    return rapportFilters ? JSON.parse(rapportFilters) : null;
  },
  
  removeRapportFilter: () => {
    localStorage.removeItem('rapportFilter');
  },
}

export default LocalStorageHelper;