import AttachmentAPI from "../api/attachment-api";
import AttachmentHelper from "../helpers/attachment-helper";
import { Attachment } from "../models/attachment";
import { FormInfo } from "../models/form-info";
import { Signature } from "../models/signature";

const AttachmentService = {
  getAllForms: (): FormInfo[] => {
    return AttachmentHelper.getAllForms();
  },
  
  getAttachments: async (rapportId: string): Promise<Attachment[]> => {
    const attachmentDtos = await AttachmentAPI.getAttachments(rapportId);
    return attachmentDtos.map(attachmentDto => AttachmentHelper.attachmentFromDto(attachmentDto));
  },

  addAttachments: async (rapportId: string, files: File[]) => {
    const formData = AttachmentHelper.filesToFormData(files);
    await AttachmentAPI.addAttachments(rapportId, formData);
  },

  deleteAttachment: async (id: number) => {
    await AttachmentAPI.deleteAttachment(id);
  },

  getSignature: async (rapportId: string): Promise<Signature | null> => {
    const signatureDto = await AttachmentAPI.getSignature(rapportId);
    if (signatureDto !== null) {
      return AttachmentHelper.signatureFromDto(signatureDto);
    }

    return null;
  },

  addSignature: async (rapportId: string, signature: string): Promise<number> => {
    return await AttachmentAPI.addSignature(rapportId, signature);
  },

  updateSignature: async (rapportId: string, id: number, signature: string): Promise<number> => {
    return await AttachmentAPI.updateSignature(rapportId, id, signature);
  },
};

export default AttachmentService;