import Titlebar from "../components/Titlebar";
import { ChangeEvent, useCallback, useContext, useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { MetaContext } from "../components/Provider/MetaContextProvider";
import { Alert, Box, Button, IconButton, Stack, TextField, Typography } from "@mui/material";
import { User, defaultUser } from "../models/user";
import { SchochStack } from "../styles/styles";
import UserService from "../services/user-service";
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import JoinFirmDialog from "../components/Dialogs/JoinFirmDialog";
import HelpDialog from "../components/Dialogs/HelpDialog";
import InfoText from "../info/info-text";
import UserFirmService from "../services/user-firm-service";
import FirmService from "../services/firm-service";
import debounce from "lodash/debounce";
import { ConnectAccount, defaultConnectAccount } from "../models/connect-account";
import { defaultCreateUpdateFirm } from "../models/create-update-firm";
import { AuthContext } from "../components/Provider/AuthContextProvider";

const RegistrationPage: React.FC = () => {
  const [params] = useSearchParams();
  const context = useContext(MetaContext);
  const authContext = useContext(AuthContext);
  const navigate = useNavigate();
  const [credentials, setCredentials] = useState<User>(defaultUser);
  const [connectAccount, setConnectAccount] = useState<ConnectAccount>(defaultConnectAccount);
  const [isUsernameAvailable, setIsUsernameAvailable] = useState(true);
  const [openDialog, setOpenDialog] = useState(false);
  const [openHelp, setOpenHelp] = useState(false);
  const [connected, setConnected] = useState<boolean | null>(null);
  const [error, setError] = useState<string | undefined>(undefined);

  const startRegistration = async () => {
    try {
      const usernameExists = await UserService.usernameExists(credentials.username);
      if (usernameExists) {
        return;
      }
      
      const validPassword = validatePassword(); 
      if (!validPassword) {
        return;
      }
  
      const userId = await authContext.register(credentials);
      await authContext.login(credentials.username, credentials.password);
  
      if (connected) {
        const firmExists = await context.handleAsyncOperation(
          () => FirmService.firmExists(connectAccount.firmLicence)
        );
    
        if (!firmExists) {
          await context.handleAsyncOperation(
            () => FirmService.createFirm(connectAccount.firmLicence, defaultCreateUpdateFirm)
          );
        }
    
        await authContext.joinFirm(connectAccount.firmLicence, connectAccount.userLicence, userId, !firmExists);
      }

      context.setAlertMessage('Der Account wurde erfolgreich erstellt.');
      context.setAlertSeverity('success');

    } catch {
      context.setAlertMessage('Es gab einen Fehler beim Erstellen des Benutzers.');
      context.setAlertSeverity('error');
    }
  };

  const checkConnection = async (firmLicence: string, userLicence: string) => {
    const checkConnection = await context.handleAsyncOperation(
      () => UserFirmService.licenceAvailableQuarantine(firmLicence, userLicence)
    );

    setConnected(checkConnection);
  };

  const validatePassword = (): boolean => {
    const passwordRegex = /^(?=.*[A-Z])(?=.*[!@#$%^&*_+-])(.{8,})$/;
    
    let hasError = undefined;
    if (!passwordRegex.test(credentials.password)) {
      hasError = 'Das Passwort muss aus mind. 8 Zeichen, 1 Grossbuchstaben und einem Spezialzeichen bestehen.';
    } else if (credentials.password !== credentials.confirmPassword) {
      hasError = 'Die Passwörter stimmen nicht überein.';
    }

    setError(hasError);
    return !!!hasError;
  };

  const checkUsernameAvailability = useCallback(debounce(async (value: string) => {
    const isAvailable = await UserService.usernameExists(value);
    setIsUsernameAvailable(!isAvailable);
  }, 400), []);

  const updateUsername = async (evt: ChangeEvent<HTMLInputElement>) => {
    const newValue = evt.target && evt.target.value;
    setCredentials({...credentials, username: newValue});
    if (!!newValue) {
      checkUsernameAvailability(newValue);
    } else {
      setIsUsernameAvailable(true);
    }
  };

  const updatePassword = (evt: ChangeEvent<HTMLInputElement>) => {
    const newValue = evt.target && evt.target.value;
    setCredentials({...credentials, password: newValue});
  };

  const updateConfirmPassword = (evt: ChangeEvent<HTMLInputElement>) => {
    const newValue = evt.target && evt.target.value;
    setCredentials({...credentials, confirmPassword: newValue});
  };

  const updateFirstName = (evt: ChangeEvent<HTMLInputElement>) => {
    const newValue = evt.target && evt.target.value;
    setCredentials({...credentials, firstName: newValue});
  };

  const updateLastName = (evt: ChangeEvent<HTMLInputElement>) => {
    const newValue = evt.target && evt.target.value;
    setCredentials({...credentials, lastName: newValue});
  };

  useEffect(() => {
    const firmLicence = params.get('f') || '';
    const userLicence = params.get('b') || '';
    if (!!firmLicence && !!userLicence) {
      checkConnection(firmLicence, userLicence);
    }

    const username = params.get('n') || '';
    if (!!username) {
      checkUsernameAvailability(username);
    }

    setCredentials(prev => ({...prev, username: username}));
    setConnectAccount({ firmLicence: firmLicence, userLicence: userLicence });
  }, []);

  if (authContext.isAuthenticated) {
    navigate('/overview');
  };

  const anonymousView = () => {
    return (
      <SchochStack sx={{ gap: 2, padding: 2, alignSelf: 'center', }} >
        <Box display='flex' justifyContent='right'>
          <IconButton
            sx={{padding: theme => theme.spacing(0)}}
            onClick={() => setOpenHelp(true)}
          >
            <HelpOutlineIcon />
          </IconButton>
        </Box>
        <Box display='flex' flexDirection='row'>
          <Typography sx={{width: 180, alignSelf: 'center'}}>Benutzername</Typography>
          <TextField
            sx={{ flex: 1 }}
            id="username"
            type="text"
            value={credentials.username}
            onChange={updateUsername}
            size="small"
            error={!isUsernameAvailable}
            helperText={!isUsernameAvailable ? "Benutzername nicht verfügbar" : ""}
          />
        </Box>
        <Box display='flex' flexDirection='row'>
          <Typography sx={{width: 180, alignSelf: 'center'}}>Passwort</Typography>
          <TextField
            sx={{ flex: 1 }}
            id="password"
            type="password"
            onChange={updatePassword}
            size="small"
            required
          />
        </Box>
        <Box display='flex' flexDirection='row'>
          <Typography sx={{width: 180, alignSelf: 'center'}}>Passwort bestätigen</Typography>
          <TextField
            sx={{ flex: 1 }}
            id="confirmPassword"
            type="password"
            onChange={updateConfirmPassword}
            size="small"
            required
          />
        </Box>
        {/*<Box display='flex' flexDirection='row'>
          <Typography sx={{width: 180, alignSelf: 'center'}}>Vorname</Typography>
          <TextField
            sx={{ flex: 1 }}
            type="text"
            onChange={updateFirstName}
            size="small"
            required
          />
        </Box>
        <Box display='flex' flexDirection='row'>
          <Typography sx={{width: 180, alignSelf: 'center'}}>Nachname</Typography>
          <TextField
            sx={{ flex: 1 }}
            type="text"
            onChange={updateLastName}
            size="small"
            required
          />
        </Box>*/}
        {connected === null && (
        <Box sx={{ height: 40, display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
          <Typography sx={{ alignSelf: 'center' }}>Mit SchochAUFTRAG verknüpfen</Typography>
          <IconButton onClick={() => setOpenDialog(true)}>
            <OpenInNewIcon />
          </IconButton>
        </Box>
        )}
        {connected === true && (
          <Box sx={{ height: 40, display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
            <Typography sx={{ alignSelf: 'center' }}>Verknüpfung wurde hergestellt</Typography>
            <IconButton onClick={() => setOpenDialog(true)}>
              <CheckIcon fontSize="large" color="success" />
            </IconButton>
          </Box>
        )}
        {connected === false && (
          <Box sx={{ height: 40, display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
            <Typography sx={{ alignSelf: 'center' }}>Verknüpfung fehlgeschlagen</Typography>
            <IconButton onClick={() => setOpenDialog(true)}>
              <ClearIcon fontSize="large" color="error" />
            </IconButton>
          </Box>
        )}
        {error && <Alert severity="error">{error}</Alert>}
        <Stack direction="row" gap={2} justifyContent='end'>
          <Button
            sx={{ width: '50%', background: theme => theme.palette.background.paper, }}
            variant="outlined"
            onClick={() => navigate("/login")}>
            Zum Login
          </Button>
          <Button
            sx={{ width: '50%'}}
            variant="contained"
            onClick={startRegistration}>
            Registrieren
          </Button>
        </Stack>
        <HelpDialog title="Account mit SchochAuftrag verknüpfen" description={InfoText.registration} isOpen={openHelp} onClose={() => setOpenHelp(false)} />
        <JoinFirmDialog isOpen={openDialog} connectAccount={connectAccount} setConnectAccount={setConnectAccount} onConfirm={() => { setOpenDialog(false); checkConnection(connectAccount.firmLicence, connectAccount.userLicence); }} onClose={() => setOpenDialog(false)} />
      </SchochStack>
    );
  };

  return (
    <Stack>
      <Titlebar title="Registration" iconName="" />
      {!authContext.isAuthenticated && anonymousView()}
    </Stack>
  );
};

export default RegistrationPage;