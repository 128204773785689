export type User = {
    id: number;
    profilePicture: Blob | null;
    username: string;
    password: string;
    confirmPassword: string;
    firstName: string;
    lastName: string;
    color: string | null;
    isAdmin: boolean;
    workload: number;
    hourlyRate: number;
    productNr: number | null;
  };
  
  export const defaultUser: User = {
    id: 0,
    profilePicture: null,
    username: '',
    password: '',
    confirmPassword: '',
    firstName: '',
    lastName: '',
    color: null,
    isAdmin: false,
    workload: 0,
    hourlyRate: 0,
    productNr: null,
  };
