import { Box, Button, IconButton, Link, Paper, PopoverPosition, Stack, Tooltip, Typography } from "@mui/material";
import { Rapport } from "../models/rapport";
import { useNavigate } from "react-router-dom";
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { Row, SchochStack } from "../styles/styles";
import DescriptionIcon from '@mui/icons-material/Description';
import GoogleMapsLink from "./GoogleMapsLink";
import { useEffect, useState } from "react";
import DynamicIcon from "./DynamicIcon";
import TotalDialog from "./Dialogs/TotalDialog";
import { PriceTotal } from "../models/price-total";
import { roundCHF } from "../helpers/utils";

type OverviewRapportProps = {
  rapport: Rapport;
  priceTotal: PriceTotal;
};

const OverviewRapport = (props: OverviewRapportProps) => {
  const navigate = useNavigate();
  const [mapsLink, setMapsLink] = useState("");
  const [anchorEl, setAnchorEl] = useState<PopoverPosition | null>(null);

  const handleTotalClick = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setAnchorEl({ top: event.clientY - 6, left: event.clientX + 2 });
  };

  const calculateRemainingTime = (futureDate: Date): string => {
    const now = new Date();
    const current = now.getTime();
    const target = new Date(futureDate).getTime();
    const difference = target - current;

    const isToday = futureDate.toDateString() === now.toDateString();
  
    if (difference <= 0) return "Termin hat begonnen";
  
    const hours = Math.floor((difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    const minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));

    if (!isToday) {
      return props.rapport.dueDate?.toLocaleDateString('de-DE', { weekday: 'short', day: '2-digit', month: 'short', year: 'numeric', hour: '2-digit', minute: '2-digit' }) ?? '';
    }

    if (hours !== 0) {
      return `Heute, um ${props.rapport.dueDate?.toLocaleTimeString('de-DE', { hour: '2-digit', minute: '2-digit' })}`;
    }

    if (minutes <= 1) {
      return 'Jetzt';
    }

    return `In ${minutes} Minuten`;
  };

  useEffect(() => {
    const link = `${props.rapport.customers[1].street} ${props.rapport.customers[1].postalCode} ${props.rapport.customers[1].city}`;
    setMapsLink(link.trim());
  }, []);

  return (
    <SchochStack>
      <Row sx={{ justifyContent: 'space-between' }}>
        <Typography variant="h6">Nächster Rapport</Typography>
        <Typography variant="h6">{(props.rapport.dueDate !== null ? calculateRemainingTime(props.rapport.dueDate) : '(Kein Datum)')}</Typography>
      </Row>   
      <Stack sx={{ gap: 2, flex: 1, }}>
        <Box display='flex' flexDirection='row'>
          <Typography
            sx={{
              flex: 1,
              textOverflow: 'ellipsis',
              overflow: 'hidden',
              whiteSpace: 'nowrap',
              fontWeight: 'bold',
            }}
          >
            {props.rapport.betreff}
          </Typography>
        </Box>
        <Box display='flex' flexDirection='row' gap={2} width='100%'>
          <Paper sx={{
            padding: 2,
            position: 'relative'
          }}>
            <Box display='flex' flexDirection='row' gap={2}>
              <LocationOnIcon />
              <Typography sx={{
                flex: 1,
                textAlign: 'center',
                fontWeight: 'bold',
              }}>
                Ausführungsort
              </Typography>
            </Box>
            <Typography sx={{
              textOverflow: 'ellipsis',
              overflow: 'hidden',
              whiteSpace: 'nowrap',
            }}>{props.rapport.customers[1].firstname} {props.rapport.customers[1].lastname}</Typography>
            <Typography sx={{
              textOverflow: 'ellipsis',
              overflow: 'hidden',
              whiteSpace: 'nowrap',
            }}>{props.rapport.customers[1].street}</Typography>
            <Typography sx={{
              textOverflow: 'ellipsis',
              overflow: 'hidden',
              whiteSpace: 'nowrap',
            }}>{props.rapport.customers[1].postalCode} {props.rapport.customers[1].city}</Typography>
            {!!props.rapport.customers[1].phone && <Typography sx={{
              textOverflow: 'ellipsis',
              overflow: 'hidden',
              whiteSpace: 'nowrap',
            }}><Link href={"tel:" + props.rapport.customers[1].phone} color="inherit">{props.rapport.customers[1].phone}</Link></Typography>}
            {!!props.rapport.customers[1].mail && <Link href={"mailto:" + props.rapport.customers[1].mail} color="inherit"><Typography>{props.rapport.customers[1].mail}</Typography></Link>}
            {!!mapsLink && 
              <Box sx={{ position: 'absolute', bottom: 5, right: 5}}>
                <GoogleMapsLink address={mapsLink} />
              </Box>
            }
          </Paper>
        </Box>
        <Row sx={{ gap: 2 }}>
          <DescriptionIcon />
          <Typography sx={{width: '100%', textOverflow: 'ellipsis', overflow: 'hidden', }}>{props.rapport.description}</Typography>
        </Row>
      </Stack>
      <Row sx={{ justifyContent: 'space-between' }}>
        <Button variant="outlined" sx={{ width: 100, bottom: 0, background: (theme) => theme.palette.background.paper }} onClick={() => navigate(`/rapport/${props.rapport.id}/edit`)}>Bearbeiten</Button>
        {props.rapport.showPrices && <Row sx={{ gap: 2, alignItems: 'center' }}>
          <Tooltip title="Berechnung anzeigen">
            <IconButton onClick={handleTotalClick}>
              <DynamicIcon iconName="Sell" />  
            </IconButton>
          </Tooltip>
            <Typography>{props.priceTotal.total.toFixed(2)} CHF</Typography>
          </Row>}
      </Row>
      <TotalDialog priceTotal={props.priceTotal} isOpen={!!anchorEl} onClose={() => setAnchorEl(null)} anchorEl={anchorEl} />
    </SchochStack>
  );
};

export default OverviewRapport;