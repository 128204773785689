import { Button, Stack } from "@mui/material";
import NotFoundSvg from "../svg/NotFoundSvg";
import { useNavigate } from "react-router-dom";
import { useContext } from "react";
import { AuthContext } from "../components/Provider/AuthContextProvider";

const NotFoundPage: React.FC = () => {
  const authContext = useContext(AuthContext);
  const navigate = useNavigate();

  return (
    <Stack alignItems='center' gap={2}>
      <NotFoundSvg />
      {!authContext.isAuthenticated && <Button sx={{ width: 200, justifySelf: 'center' }} variant="contained" onClick={() => navigate('/')}>Zur Startseite</Button>}
    </Stack>
  );
};

export default NotFoundPage;