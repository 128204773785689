import React, { ChangeEvent, useContext, useEffect, useState } from "react";
import { Alert, Box, Button, Chip, CircularProgress, IconButton, Stack, TextField, Typography } from "@mui/material";
import { MetaContext } from "./Provider/MetaContextProvider";
import { SchochStack } from "../styles/styles";
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import AllMembersDialog from "./Dialogs/AllMembersDialog";
import MemberTable from "./MemberTable";
import { MeContext } from "./Provider/MeContextProvider";
import FirmService from "../services/firm-service";
import AddressBookDialog from "./Dialogs/AddressBookDialog";
import AddressBookTable from "./AddressBookTable";
import SyncIcon from '@mui/icons-material/Sync';
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import InfoText from "../info/info-text";
import HelpDialog from "./Dialogs/HelpDialog";
import MaterialService from "../services/material-service";
import { defaultCreateUpdateFirm, CreateUpdateFirm } from "../models/create-update-firm";
import PostItTable from "./PostItTable";
import { FirmContext } from "./Provider/FirmContextProvider";
import PostItDialog from "./Dialogs/PostItDialog";

const FirmDetails: React.FC = () => {
  const context = useContext(MetaContext);
  const meContext = useContext(MeContext);
  const firmContext = useContext(FirmContext);
  const [updateFirm, setUpdateFirm] = useState<CreateUpdateFirm>(defaultCreateUpdateFirm);
  const [editInfo, setEditInfo] = useState(false);
  const [allMembersOpen, setAllMembersOpen] = useState(false);
  const [addressBookOpen, setAddressBookOpen] = useState(false);
  const [priceHelpOpen, setPriceHelpOpen] = useState(false);
  const [postItOpen, setPostItOpen] = useState(false);
  const [postItDialogOpen, setPostItDialogOpen] = useState(false);
  const [isLoadingPrices, setIsLoadingPrices] = useState(false);

  const handleSaveInfo = async () => {
    const id = context.handleAsyncOperation(
      () => FirmService.updateFirm(meContext.currentFirm!.firmLicence, updateFirm)
    );
    if (id === null) {
      context.setAlertMessage("Ein Fehler ist aufgetreten.");
      context.setAlertSeverity("error");
      return;
    }

    context.setAlertMessage("Die Änderungen wurden erfolgreich gespeichert.");
    context.setAlertSeverity("success");
    const weeklyTarget = Number.parseFloat(updateFirm.weeklyTarget);
    meContext.setCurrentFirm(prev => ({ ...prev!, firmName: updateFirm.firmName, weeklyTarget: !isNaN(weeklyTarget) ? weeklyTarget : 0 }));
    setEditInfo(false);
  };

  const updateFirmname = (evt: ChangeEvent<HTMLInputElement>) => {
    const newValue = evt.target && evt.target.value;
    setUpdateFirm(prev => ({ ...prev, firmName: newValue }));
  };

  const updateWeeklyTarget = (evt: ChangeEvent<HTMLInputElement>) => {
    const newValue = evt.target.value;
    setUpdateFirm(prev => ({ ...prev, weeklyTarget: newValue }));
  };

  const loadPrices = async () => {
    const isLoading = await fetchLoadingStatus();
    if (!isLoading) {
      const jobId = await MaterialService.loadPrices();
      if (jobId === '') {
        context.setAlertMessage("Keine Preise gefunden! Preise zuerst aus SchochAUFTRAG übermitteln.");
        context.setAlertSeverity('warning');
      } else if (jobId === 'UP_TO_DATE') {
        context.setAlertMessage("Die Preise sind bereits auf dem neuesten Stand.");
        context.setAlertSeverity('info');
      } else {
        setIsLoadingPrices(true);
      }
    }
  };

  const cancelLoad = async () => {
      setIsLoadingPrices(false);
      await MaterialService.cancelPriceLoading();
  };

  const fetchLoadingStatus = async (): Promise<boolean> => {
    const data = await FirmService.getPriceStatus();
    setIsLoadingPrices(data);
    return data;
  };

  // Todo RSR-102: Replace Polling with SignalR
  useEffect(() => {
    let intervalId: NodeJS.Timeout | undefined;
    if (isLoadingPrices) {
      intervalId = setInterval(() => {
        fetchLoadingStatus();
        if (!isLoadingPrices) {
          clearInterval(intervalId);
        }
      }, 30000);
    }
  
    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [isLoadingPrices]);

  useEffect(() => {
    fetchLoadingStatus();
  }, [addressBookOpen]);

  useEffect(() => {
    setUpdateFirm({
      firmName: meContext.currentFirm?.firmName ?? "",
      weeklyTarget: meContext.currentFirm?.weeklyTarget.toLocaleString() ?? "",
    });
  }, []);

  return (
    <Box>
      <Stack sx={{ padding: { sm: 2 }, paddingTop: 0, gap: 2 }}>
        <SchochStack>
          <Typography variant='h6'>Firmeninfo</Typography>
          <Box display='flex' flexDirection='row' justifyContent='space-between' alignItems='end'>
            <Stack gap={1}>
              {!editInfo && <Typography><strong>Firmenname:</strong> {meContext.currentFirm?.firmName}</Typography>}
              {editInfo && <TextField
                value={updateFirm.firmName}
                label='Firmenname'
                type="text"
                size="small"
                onChange={updateFirmname}
              />}
              {!editInfo && <Typography><strong>Wöchentliche Sollzeit:</strong> {meContext.currentFirm?.weeklyTarget}</Typography>}
              {editInfo && <TextField
                value={updateFirm.weeklyTarget}
                label='Wöchentliche Sollzeit in h'
                type="number"
                size="small"
                onChange={updateWeeklyTarget}
              />}
            </Stack>
            {!editInfo && meContext.currentUser?.isAdmin && <Button variant="contained" onClick={() => setEditInfo(true)}>Bearbeiten</Button>}
            {editInfo && <Button variant="contained" onClick={handleSaveInfo}>Speichern</Button>}
          </Box>
        </SchochStack>
        {meContext.currentUser?.isAdmin && <SchochStack>
          <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
            <Typography variant="h6">Preise</Typography>
            <IconButton onClick={() => setPriceHelpOpen(true)}><HelpOutlineIcon /></IconButton>
          </Box>
          <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', flex: 1 }}>
            <Box sx={{ display: 'flex', flexDirection: 'row', gap: 1 }}>
              <Typography fontWeight='bold'>Tarifdaten:</Typography>
              <Box sx={{ display: 'flex', flexDirection: 'row', gap: 1, }}>
                <Chip label="Steiger" size="small" disabled={!meContext.currentFirm?.hasSteiger} />
                <Chip label="Eldas" size="small" disabled={!meContext.currentFirm?.hasEldas} />
                <Chip label="NPK" size="small" disabled={!meContext.currentFirm?.hasNpk} />
              </Box>
            </Box>
            <IconButton onClick={meContext.syncCatalogLicences}>
              <SyncIcon fontSize="small" />
            </IconButton>
          </Box>
          <Stack gap={2}>
            <Box sx={{ display: 'flex', flexDirection: 'row', gap: 2 }}>
              <Button variant="contained" onClick={loadPrices} disabled={isLoadingPrices}>Preise laden</Button>
              {isLoadingPrices && <Button variant="outlined" onClick={cancelLoad}>Abbrechen</Button>}
              {isLoadingPrices && <CircularProgress
                sx={{ transform: "translate(-50%, -50%)" }}
                color="inherit"
              />}
            </Box>
            {isLoadingPrices && <Alert severity="info" variant="outlined">Das Laden der Preise dauert etwa 15 Minuten.</Alert>}
            <Typography><strong>Letzte Aktualisierung:</strong> {meContext.currentFirm?.latestPriceUpdate ? meContext.currentFirm.latestPriceUpdate.toLocaleString('de-DE', { weekday: 'short', day: '2-digit', month: 'short', year: '2-digit', hour: '2-digit', minute: '2-digit' }) : 'Nie'}</Typography>
          </Stack>
        </SchochStack>}
        <SchochStack>
          <Box display='flex' flexDirection='row' justifyContent='space-between'>
            <Box sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
            }}>
              <Typography variant='h6'>Mitglieder ({firmContext.users.length})</Typography>
              <IconButton
                onClick={() => setAllMembersOpen(true)}
              >
                <ArrowForwardIosIcon />
              </IconButton>
            </Box>
          </Box>
          <MemberTable size={5} />
          {firmContext.users.length > 5 && <Button size='small' sx={{ width: '200px', backgroundColor: (theme) => theme.palette.background.paper }} variant='outlined' onClick={() => setAllMembersOpen(true)}>Alle Anzeigen</Button>}
        </SchochStack>
        <SchochStack>
          <Box sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
          }}>
            <Typography variant='h6'>Adressbuch ({firmContext.addressBookCount})</Typography>
            <IconButton
              onClick={() => setAddressBookOpen(true)}
            >
              <ArrowForwardIosIcon />
            </IconButton>
          </Box>
          <AddressBookTable isDetailed={false} size={5} />
          {firmContext.addressBook.length > 5 && <Button size='small' sx={{ width: '200px', backgroundColor: (theme) => theme.palette.background.paper }} variant='outlined' onClick={() => setAddressBookOpen(true)}>Alle Anzeigen</Button>}
        </SchochStack>
        {meContext.currentUser?.isAdmin && <SchochStack>
          <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
              <Typography variant='h6'>Vorgang-Merkmale</Typography>
              <IconButton
                onClick={() => setPostItDialogOpen(true)}
              >
                <ArrowForwardIosIcon />
              </IconButton>
            </Box>
            <IconButton onClick={() => setPostItOpen(true)}><HelpOutlineIcon /></IconButton>
          </Box>
          <PostItTable size={5} />
          {meContext.postIts.length > 5 && <Button size='small' sx={{ width: '200px', backgroundColor: (theme) => theme.palette.background.paper }} variant='outlined' onClick={() => setPostItDialogOpen(true)}>Alle Anzeigen</Button>}
        </SchochStack>}
      </Stack>
      <AllMembersDialog isOpen={allMembersOpen} onClose={() => setAllMembersOpen(false)} />
      <AddressBookDialog isOpen={addressBookOpen} onClose={() => setAddressBookOpen(false)} pagination={true} />
      <PostItDialog isOpen={postItDialogOpen} onClose={() => setPostItDialogOpen(false)}/>
      <HelpDialog title={'Preise laden'} description={InfoText.loadPrices} isOpen={priceHelpOpen} onClose={() => setPriceHelpOpen(false)} />
      <HelpDialog title={'Vorgang-Merkmale bearbeiten'} description={InfoText.defaultPostIts} isOpen={postItOpen} onClose={() => setPostItOpen(false)} />
    </Box>
  );
};

export default FirmDetails;