import { ChangeEvent, useContext, useState } from "react";
import { Box, Button, IconButton, Stack, TextField, Typography } from "@mui/material";
import { SchochStack } from "../styles/styles";
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import FirmService from "../services/firm-service";
import { MetaContext } from "./Provider/MetaContextProvider";
import HelpDialog from "./Dialogs/HelpDialog";
import InfoText from "../info/info-text";
import { ConnectAccount, defaultConnectAccount } from "../models/connect-account";
import UserFirmService from "../services/user-firm-service";
import { MeContext } from "./Provider/MeContextProvider";
import { defaultCreateUpdateFirm } from "../models/create-update-firm";
import { AuthContext } from "./Provider/AuthContextProvider";

const NoFirmDetails = () => {
  const context = useContext(MetaContext);
  const meContext = useContext(MeContext);
  const authContext = useContext(AuthContext);
  const [openHelp, setOpenHelp] = useState(false);
  const [accountInfo, setAccountInfo] = useState<ConnectAccount>(defaultConnectAccount);

  const updateFirmLicence = (evt: ChangeEvent<HTMLInputElement>) => {
    const newValue = evt.target && evt.target.value;
    setAccountInfo({...accountInfo, firmLicence: newValue });
  };

  const updateUserLicence = (evt: ChangeEvent<HTMLInputElement>) => {
    const newValue = evt.target && evt.target.value;
    setAccountInfo({...accountInfo, userLicence: newValue });
  };

  const connectAccount = async () => {
    const checkConnection = await context.handleAsyncOperation(
      () => UserFirmService.licenceAvailableQuarantine(accountInfo.firmLicence, accountInfo.userLicence)
    );

    if (checkConnection) {
      const firmExists = await context.handleAsyncOperation(
        () => FirmService.firmExists(accountInfo.firmLicence)
      );
  
      if (!firmExists) {
        await context.handleAsyncOperation(
          () => FirmService.createFirm(accountInfo.firmLicence, defaultCreateUpdateFirm)
        );
      }

      await authContext.joinFirm(accountInfo.firmLicence, accountInfo.userLicence, meContext.currentUser!.id, !firmExists); // Info: The creator of a new firm is an admin
    } else {
      context.setAlertMessage("Die eingegebene Lizenz ist nicht gültig.");
      context.setAlertSeverity("error");
    }
  };

  return (
    <Stack sx={{ padding: { xs: 0, sm: 2 }, paddingTop: { sm: 0 }, gap: 2}}>
      <SchochStack>
        <Box display='flex' flexDirection='row' justifyContent='space-between'>
          <Typography sx={{ flex: 1, textAlign: 'center' }} variant="h6">Mit SchochAUFTRAG verknüpfen</Typography>
          <IconButton
            sx={{padding: theme => theme.spacing(0)}}
            onClick={() => setOpenHelp(true)}
          >
            <HelpOutlineIcon />
          </IconButton>
        </Box>
        <TextField
          label="Firmen-Lizenz"
          onChange={updateFirmLicence}
          size="small"
        />
        <TextField
          label="Benutzer-Lizenz"
          onChange={updateUserLicence}
          size="small"
        />
        <Box display='flex' flexDirection='row' gap={2} justifyContent='center'>
        <Button
          sx={{ width: '50%', }}
          variant='contained'
          onClick={connectAccount}
        >
          Verknüpfen
        </Button>
        </Box>
      </SchochStack>
      <HelpDialog title="Verknüpfung" description={InfoText.connectAccount} isOpen={openHelp} onClose={() => setOpenHelp(false)} />
    </Stack>
  );
};

export default NoFirmDetails;